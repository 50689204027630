import Vuex from 'vuex'

const store = new Vuex.Store({
    state: {
        loading: false,
        login: false
    },
    mutations: {
        showLoading (state) {
            state.loading = true;
        },
        hideLoading(state) {
            state.loading = false;
        },
        loginSuccess(state) {
            state.login = true;
        }
    }
})

export default store;