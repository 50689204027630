<template>
  <div class="question-answer">
    <div class="back brown" @click="back">回首页</div>
    <div class="question-answer__scroll" ref="scrollView">
      <div class="question-answer__header">
        <img class="question-answer__logo" src="@/assets/pop.png" alt="">
        <h2 class="question-answer__title">欢迎使用智慧党建助手</h2>
        <h2 class="question-answer__title question-answer__title--sub">AI支持的语音问答助手</h2>
      </div>

      <div class="custom-container question-answer__tip" v-if="type === 'professional'">
        <div class="question-answer__tip-item" v-for="(item, index) in list" :key="index">
          <div class="question-answer__tip-title">{{ item.title }}</div>
          <div v-for="(question, i) of item.question" class="question-answer__tip-question">
            {{ i + 1 }}.{{ question }}
          </div>
        </div>
      </div>

      <!--      <div class="question-answer__tip" v-else>-->
      <!--        <div class="question-answer__tip-item" v-for="(item, index) in gossipList" :key="index"-->
      <!--             @click="quickQuestion(item)">{{ item }}-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="custom-container question-answer__content">
        <div class="question-answer__reserve">
          <div v-for="(item, index) of chatList" :key="index" class="question-answer__item"
               :class="item.type === 'question'? 'right' : 'left'">
            <div>
              <div class="question-answer__desc" :class="item.type === 'question'? 'question' : 'answer'" @click="playAnswerAudio(item)">
                {{ item.content }}
                <span v-if="item.loading"><img src="@/assets/loading.gif" alt="" class="loading-img"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="custom-container question-answer__bottom">
      <!--      <recorder class="question-answer__img&#45;&#45;recorder" :show-wave="false" :status="status" @onComplete="uploadAudio"></recorder>-->
      <in-time-recorder :show-wave="false" :status="status" :autoStop="true" @message="onMessage"
                        @onStop="handleStop" @onRecording="changeRecordType"></in-time-recorder>
      <img class="question-answer__img--clear" src="@/assets/clear.png" alt="" @click="clear">

      <div v-show="inputType === 'voice'"
           :class="recordStatus === '' ? 'question-answer__button' : 'question-answer__button active'" @click="onClick">
        <img src="@/assets/pointer.jpg" class="question-answer__button-icon" alt="">
        <span v-if="recordStatus === ''">点击说话</span>
        <span v-else>收音中，点击停止</span>
      </div>

      <el-input class="question-answer__input" v-show="inputType === 'input'" v-model="word" placeholder="请输入内容"
                @keyup.enter.native="submitText()"></el-input>

      <img class="question-answer__img--keyboard" src="@/assets/keyboard.png" alt="" @click="changeInputType">
    </div>
  </div>
</template>

<script>
import {getAli, getGPTAnswer, getWavById, getWavIdByWords, uploadRecord} from "@/service/home.service";
import store from "@/store/store";
import InTimeRecorder from "@/components/inTimeRecorder.vue";

export default {
  name: "questionAnswer",
  data() {
    return {
      chatList: [],
      list: [
        {
          title: "《“二十大”精神学习》",
          question: [
            "在中国共产党第二十次全国代表大会上的报告",
            "二十大学习手账问答"
          ]
        },
        {
          title: "《党务基础知识》",
          question: [
            "党章党规基础知识",
            "党务基础知识",
            "党务基础知识疑难问答"
          ]
        },
        {
          title: "《党章学习》",
          question: [
            "党章二十大修改后"
          ]
        },
        {
          title: "《党内文件学习》",
          question: [
            "中国共产党党员教育管理工作条例",
            "中国共产党支部工作条例（试行）",
            "中国共产党党旗党徽条例",
            "中国共产党发展党员工作细则",
          ]
        },
      ],
      status: '',
      recordStatus: '', // recording or ''
      type: 'professional',
      player: '',
      word: '',
      inputType: 'voice',
      tipFixed: false,
      isTest: false,
      wordLoading: false,
      updateWord: '',
      receiveWord: false
    }
  },
  components: {
    InTimeRecorder,
  },
  methods: {
    onClick() {
      if (this.recordStatus === 'recording') {
        this.stopRecord();
      } else {
        this.startRecord();
      }
    },
    stopRecord() {
      if (this.recordStatus === '') {
        return
      }

      this.status = '';
      this.updateWord = '';
      this.recordStatus = '';
      if (this.receiveWord) {
        this.submitWord()
      }
    },
    startRecord() {
      this.receiveWord = false
      this.stopVoice();
      if (this.status === 'start') {
        this.status = ''
        // this.uploadAudio()
        return
      }

      this.status = 'start'
    },
    playAnswerAudio(item) {
      if (this.player) {
        this.player.pause()
        this.player = ''
      } else {
        this.playAudio(item.voiceID)
      }
    },
    handleStop() {
      this.stopRecord()
    },
    changeRecordType() {
      this.recordStatus = 'recording'
    },
    clear() {
      this.status = ''
      this.chatList = []
      this.stopVoice();
    },
    // clickType(type) {
    //   this.type = type;
    //   this.clear();
    // },
    onMessage(word) {
      if (!word) {
        store.commit('hideLoading')
        return
      }
      this.receiveWord = true
      const _date = new Date().getTime()

      if (!this.updateWord) {
        this.chatList.push({
          type: 'question',
          content: word,
          createTime: _date
        })
        this.updateWord = _date
      } else {
        for (const item of this.chatList) {
          if (item.createTime === this.updateWord) {
            item.content = item.content + word
          }
        }
        this.chatList = [...this.chatList]
      }

      this.scrollToBottom()
      store.commit('hideLoading')
    },
    submitWord() {
      this.wordLoading = true
      this.scrollToBottom();
      const question = [];
      for (const item of this.chatList) {
        if (item.type === 'question') {
          question.push(item)
        }
      }

      let _answer = ''

      let _word = question[question.length - 1]['content']

      getGPTAnswer(_word, this.type, this.isTest)
          .then(answer => {
            _answer = answer['result'] || answer
            return getWavIdByWords(answer['result'] || answer)
          })
          .then(res => {
            return getWavById(res['data'])
          })
          .then(res => {
            this.wordLoading = false
            this.chatList.push({
              type: 'answer',
              content: _answer,
              createTime: new Date().getTime(),
              voiceID: res['data']
            })
            this.scrollToBottom()
            // this.playAudio(res['data'])
            // store.commit('hideLoading')
          })
          .catch(err => {
            this.$message('语音识别错误');
            store.commit('hideLoading')
          })
    },
    uploadAudio(data) {
      store.commit('showLoading')
      const formData = new FormData()
      formData.append('file', data);

      let _result

      uploadRecord(formData)
          .then(res => {
            return getAli(res)
          })
          .then(res => {
            let _word = '';
            if (!res?.Sentences.length) {
              store.commit('hideLoading')
              return new Promise(() => {
              })
            }

            for (let item of res.Sentences) {
              _word = _word + item['Text']
            }

            this.chatList.push({
              type: 'question',
              content: _word,
              createTime: new Date().getTime()
            })
            this.scrollToBottom()
            store.commit('hideLoading')
            this.wordLoading = true
            this.scrollToBottom();
            return _word
          })
          .then(text => {
            return getGPTAnswer(text, this.type, this.isTest)
          })
          .then(answer => {
            _result = answer['result'] || answer
            return getWavIdByWords(answer['result'] || answer)
          })
          .then(res => {
            return getWavById(res['data'])
          })
          .then(res => {
            this.wordLoading = false
            this.chatList.push({
              type: 'answer',
              content: _result,
              createTime: new Date().getTime(),
              voiceID: res['data']
            })
            this.scrollToBottom()

            // this.playAudio(res['data'])
            // store.commit('hideLoading')
          })
          .catch(err => {
            this.$message('语音识别错误');
            store.commit('hideLoading')
          })
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const scrollView = this.$refs.scrollView
        scrollView.scrollTop = scrollView.scrollHeight
      })
    },
    playAudio(url) {
      this.stopVoice();
      // const url = URL.createObjectURL(blob)
      this.player = new Audio(url)
      this.player.play()
    },
    changeInputType() {
      if (this.inputType === 'voice') {
        this.inputType = 'input'
      } else {
        this.inputType = 'voice'
      }
      this.word = '';
    },
    quickQuestion(text) {
      this.submitText(text)
    },
    submitText(text) {
      this.stopVoice();
      if (!this.word && !text) {
        this.$message('请输入问题');
        return
      }

      let _answer

      this.chatList.push({
        type: 'question',
        content: text || this.word,
        createTime: new Date().getTime()
      })
      this.wordLoading = true
      this.scrollToBottom();
      // store.commit('showLoading')
      getGPTAnswer(text || this.word, this.type, this.isTest)
          .then(res => {
            this.scrollToBottom()
            this.word = '';
            return res['result'] || res
          })
          .then(answer => {
            _answer = answer
            return getWavIdByWords(answer)
          })
          .then(res => {
            return getWavById(res['data'])
          })
          .then(res => {
            this.wordLoading = false
            this.chatList.push({
              type: 'answer',
              content: _answer,
              createTime: new Date().getTime(),
              voiceID: res['data']
            })
            this.scrollToBottom()

            // this.playAudio(res['data'])
          })
          .catch(err => {
            this.$message('会话错误');
            store.commit('hideLoading')
          })
    },
    stopVoice() {
      if (this.player) {
        this.player.pause()
      }
    },
    back() {
      this.$router.back()
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player = '';
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.isTest = this.$route.query.isTest === 'true' || false
      },
      // 深度观察监听
      deep: true
    },
    wordLoading(val) {
      if (this.wordLoading) {
        this.chatList.push({
          type: 'answer',
          content: '答案生成中',
          createTime: new Date().getTime(),
          loading: true
        })
      } else {
        let arrayIndex
        for (let i in this.chatList) {
          if (this.chatList[i].loading === true) {
            arrayIndex = i
          }
        }

        if (arrayIndex) {
          this.chatList.splice(arrayIndex, 1)
        }
      }
    }
  },
  mounted() {
    this.isTest = this.$route.query.isTest === 'true' || false;
    document.onkeydown = (e) => {
      let e1 = e || event || window.event || arguments.callee.caller.arguments[0]

      if(e1 && e1.keyCode && e1.keyCode === 32){
        this.onClick()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/style/px2rem";

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.question-answer {
  height: 100%;
  position: relative;
  //background: #FDF0F0;
  background: linear-gradient(134deg, rgba(255, 250, 250, 0.5) 0%, rgba(255, 242, 242, 0.50) 50%), url('@/assets/partynav_bg.png');
  padding: px2rem(70px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__close {
    position: absolute;
    left: px2rem(20px);
    top: px2rem(20px);
    font-size: px2rem(30px);
    font-weight: bold;
    color: #FF9B9B;
    cursor: pointer;
  }

  &__header {
    padding-top: px2rem(150px);
    padding-bottom: px2rem(32px);
  }

  &__logo {
    width: px2rem(140px);
    height: px2rem(140px);
    //padding: px2rem(20px);
    //background: white;
    margin-bottom: px2rem(43px);
  }

  &__title {
    color: #9F4D2E;
    margin: 0;

    &--sub {
      font-size: px2rem(24px);
      margin-top: px2rem(15px);
    }
  }

  &__tip {
    margin-top: px2rem(100px);
    color: #C40808;
    font-size: px2rem(32px);
    display: flex;
    justify-content: space-around;

    &-item {
      cursor: pointer;
      width: px2rem(530px);
      height: px2rem(455px);
      border-radius: 10px;
      padding: px2rem(43px) px2rem(28px);
      text-align: left;
      font-weight: bold;
      font-size: px2rem(32px);
      background: white;
    }

    &-title {
      text-align: center;
    }

    &-question {
      padding-top: px2rem(30px);
      font-size: px2rem(28px);
      font-weight: 400;
      color: rgba(196, 8, 8, 0.6);
    }
  }

  &__type {
    padding-top: px2rem(70px);
    display: flex;
    justify-content: center;

    &-item {
      cursor: pointer;
      background: white;
      color: #C40808;
      padding: px2rem(13px) px2rem(50px);

      &.selected {
        color: white;
        background: linear-gradient(219deg, #FF964F 0%, #FF5D27 100%);
      }
    }

    &-item:first-child {
      border-radius: 30px 0 0 30px;
    }

    &-item:last-child {
      border-radius: 0 30px 30px 0;
    }
  }

  &__type.fixed {
    padding-bottom: px2rem(70px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #FDF0F0;
    z-index: 9999;
  }

  &__scroll {
    overflow-y: auto;
    flex: 1;
  }

  &__content {
    //overflow-y: auto;
    padding-top: px2rem(10px);
    padding-bottom: px2rem(10px);
    //height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  &__reserve {
    //overflow-y: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  &__item {
    width: 100%;
  }

  &__desc {
    max-width: 80%;
    width: fit-content;
    border-radius: 10px;
    text-align: left;
    margin-top: px2rem(25px);
    padding: px2rem(15px) px2rem(20px);
    font-size: px2rem(24px);
    color: #666666;

    &.question {
      float: right;
      background: #FFD3D3;
      position: relative;

      &:after {
        content: '';
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 10px solid #FFD3D3;
        position: absolute;
        right: 0;
        bottom: -2.5px;
      }
    }

    &.answer {
      float: left;
      background: #FFFFFF;
      position: relative;
      cursor: pointer;

      &:after {
        content: '';
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 10px solid #FFFFFF;
        position: absolute;
        left: 0;
        bottom: -2.5px;
      }
    }
  }

  &__stop {
    cursor: pointer;
    color: #FF5D27;
    //padding: px2rem(9px) px2rem(75px);
    background-image: linear-gradient(#fff, #fff), linear-gradient(234deg, rgba(255, 144, 75, 1), rgba(255, 95, 40, 1));
    background-clip: content-box, padding-box;
    border: none;
    padding: 2px;
    border-radius: px2rem(34px);
    height: px2rem(64px);
    line-height: px2rem(64px);

    span {
      background-image: linear-gradient(243deg, #FF964F 0%, #FF5D27 100%);
      color: transparent;
      background-clip: text;
    }
  }

  &__bottom {
    padding: 0 px2rem(128px);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    cursor: pointer;
    color: #C40808;
    flex: 1;
    height: px2rem(128px);
    line-height: px2rem(128px);
    background: white;
    border-radius: 50px;
    margin: 0 px2rem(50px);
    font-weight: bold;
    font-size: px2rem(36px);

    &-icon {
      position: relative;
      top: px2rem(5px);
      width: px2rem(48px);
      vertical-align: text-bottom;
      margin-right: px2rem(20px);
    }
  }

  &__button.active {
    box-shadow: 0 0 12px #0000001A;
  }

  &__input {
    height: px2rem(80px);
    margin: 0 px2rem(50px);

    :deep(.el-input__inner) {
      height: px2rem(80px);
    }
  }

  &__img {
    &--recorder {
      position: absolute;
    }

    &--clear {
      width: px2rem(88px);
      height: px2rem(88px);
      cursor: pointer;
    }

    &--micro-phone,
    &--micro-phone-disabled {
      width: px2rem(95px);
      height: px2rem(95px);
      cursor: pointer;
    }

    &--keyboard {
      width: px2rem(64px);
      height: px2rem(64px);
      cursor: pointer;
    }
  }
}

.loading-img {
  width: px2rem(70px);
  vertical-align: middle;
}
</style>
