<template>
  <div class="party-container main-bg">
    <div class="back brown" @click="back">回首页</div>
    <section class="home-container" v-show="!showRecord">
      <div v-if="meetingList.length" class="meeting__summary-container">
        <div class="meeting__summary-title">党支部委员会</div>
        <meeting-summary v-for="item of meetingList" :key="item.id" :info="item"
                         @onClick="goMeeting(item.id)"></meeting-summary>
      </div>

      <el-steps v-else direction="vertical" :active="step">
        <el-step>
          <div class="step__icon" slot="icon"></div>
          <span slot="title">确认议题 <span v-if="subject && step !== 0">:{{ subject }}</span></span>
          <div slot="description" v-if="step === 0">
            <el-input class="step__input" v-model="subject" placeholder="请输入议题…">
              <div slot="suffix">
                <img class="step__input-icon" src="@/assets/input_icon.png" alt="">
              </div>
            </el-input>
            <div class="step__tips--container">
              <div  class="step__tips-title">近期可以讨论的议题参考：</div>
              <div class="step__tips-item--container">
                <div class="step__tips-item" v-for="(item, index) of tips" :key="index" @click="fillSubject(item)">{{ item }}</div>
              </div>
            </div>
            <div>
              <div class="step__button first" @click="next" v-if="subject && step === 0">下一步</div>
            </div>

          </div>
        </el-step>
        <el-step>
          <div class="step__icon" slot="icon"></div>
          <span slot="title">确认会议时间 <span v-if="meetingTime && step !== 1">{{ meetingTime }}</span></span>
          <div slot="description" v-if="step === 1">
            <el-date-picker
                v-model="meetingTime"
                class="step__input"
                prefix-icon="''"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy/MM/dd HH:mm:ss">
              <div slot="suffix">
                <img class="step__input-icon" src="@/assets/input_icon.png" alt="">
              </div>
            </el-date-picker>
            <div>
              <div class="step__button" @click="next" v-if="meetingTime && step === 1">下一步</div>
            </div>

          </div>
        </el-step>
        <el-step>
          <div class="step__icon" slot="icon"></div>
          <span slot="title">开始会议</span>
          <div slot="description">
            <div class="step__button" @click="record" v-if="step === 2">开启智慧会议</div>
          </div>
        </el-step>
      </el-steps>
      <div class="question-answer__bottom">

        <in-time-recorder class="question-answer__img--recorder" :status="status" :autoStop="true" :show-wave="false"
                          @message="onMessage" :meetingId="meetingInfo['meetingId']"></in-time-recorder>
        <!--        <img class="question-answer__img--clear" src="@/assets/clear.png" alt="" @click="clear">-->

        <div v-show="inputType === 'voice'"
             :class="status === '' ? 'question-answer__button' : 'question-answer__button active'" @click="onClick">
          <img src="@/assets/pointer.jpg" class="question-answer__button-icon" alt="">
          <span v-if="status === ''">点击按钮，语音输入</span>
          <span v-else>收音中，点击停止</span>
        </div>

      </div>

    </section>
    <section class="container" v-show="showRecord">
      <!--      <div class="back brown" @click="back">回首页</div>-->
      <div class="record-container">
        <middleContent :startRecord="showRecord" :meeting-info="meetingInfo"></middleContent>
      </div>
    </section>
  </div>
</template>

<script>
import middleContent from "@/components/middleContent.vue";
import recorder from "@/components/recorder.vue";
import store from "@/store/store";
import {getAli, uploadRecord} from "@/service/home.service";
import {createMeeting, getAllMeeting} from "@/service/meeting.service";
import MeetingSummary from "@/components/meetingSummary.vue";
import {MeetingType} from "@/utils/enum";
import moment from "moment";
import InTimeRecorder from "@/components/inTimeRecorder.vue";

export default {
  name: "partyBranch",
  data() {
    return {
      showRecord: false,
      step: 0,
      meetingType: '',
      checkList: [],
      status: '',
      inputType: 'voice',
      subject: '',
      meetingTime: '',
      meetingList: [],
      meetingInfo: {},
      tips: ['党纪学习教育总结', '二十大党章修订解读', '二十届三中全会精神学习讨论', '入党申请人和积极分子近期思想状况', '如何走近新兴领域工作群体', '如何发挥沿街商铺参与政府实事项目', '如何吸引更多青年人参与社区治理', '支部近期可以开展的社区志愿服务', '居民区党组织和“两企三新”党组织可以共同开展哪些活动']
    }
  },
  methods: {
    next() {
      this.step++
    },
    record() {

      createMeeting({
        subject: this.subject,
        meetingTime: new Date(),
        meetingType: this.$route.path === '/partyBranch' ? MeetingType['PARTYBRANCH'] : MeetingType['PARTYGROUP'],
        location: "静安寺街道社区党群服务中心"
      })
          .then(res => {
            this.meetingInfo = {
              meetingId: res.id
            }
            this.showRecord = true;
          })
    },
    clear() {
      this.status = ''
      this.chatList = []
      this.stopVoice();
    },
    onClick() {
      if (this.status) {
        this.stopRecord();
      } else {
        this.startRecord();
      }
    },
    stopRecord() {
      this.status = '';
    },
    startRecord() {
      // this.stopVoice()
      if (this.status === 'start') {
        this.status = ''
        this.uploadAudio()
        return
      }

      this.status = 'start'
    },

    onMessage(word) {
      if (!word) {
        return
      }

      if (this.step === 0) {
        this.subject = word;
      }
      // if (this.step === 1) {
      //   this.subject = word;
      // }
    },
    uploadAudio(data) {
      store.commit('showLoading')
      const formData = new FormData()
      formData.append('file', data)

      uploadRecord(formData)
          .then(res => {
            return getAli(res)
          })
          .then(res => {
            if (!res?.Sentences.length) {
              return
            }

            if (this.step === 0) {
              this.subject = res?.Sentences[0].Text;
            }

            console.log(res?.Sentences[0].Text)

            // this.chatList.push({
            //   type: 'question',
            //   content: res?.Sentences[0].Text,
            //   createTime: new Date().getTime()
            // })
            store.commit('hideLoading')
          })
          .catch(err => {
            this.$message('语音识别错误');
            store.commit('hideLoading')
          })
    },
    back() {
      this.$router.back()
    },
    goMeeting(id) {
      this.meetingInfo = {
        meetingId: id
      }
      this.showRecord = true;
    },
    queryMeeting() {
      getAllMeeting({
        status: 'New',
        meetingType: this.$route.path === '/partyBranch' ? MeetingType['PARTYBRANCH'] : MeetingType['PARTYGROUP'],
        day: moment().format('YYYYMMDD')
      })
          .then(res => {
            this.meetingList = [...res]
          })
    },
    fillSubject(item) {
      this.subject = item;
    }
  },
  components: {InTimeRecorder, MeetingSummary, recorder, middleContent},
  mounted() {
    this.queryMeeting()
    document.onkeydown = (e) => {
      let e1 = e || event || window.event || arguments.callee.caller.arguments[0]

      if (e1 && e1.keyCode && e1.keyCode === 32) {
        this.onClick()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/style/px2rem";

.party-container {
  position: relative;
  height: 100%;
  background: #FFF2F2;
  padding: px2rem(100px) px2rem(125px);
  padding-bottom: 0;
}

.party-container.no-padding {
  padding: 0;
}

.home-container {
  padding: px2rem(92px) px2rem(250px);
}

.step {
  &__icon {
    width: px2rem(20px);
    height: px2rem(20px);
    border-radius: 50px;
    background: #D2D2D2;
  }

  &__selection-container {
    padding: px2rem(32px) 0;
    display: flex;
  }

  &__img {
    width: px2rem(30px);
    height: px2rem(30px);
    vertical-align: bottom;
  }

  &__selection {
    color: #C40808;
    font-size: px2rem(23px);
    cursor: pointer;
    width: px2rem(300px);
    background: white;
    text-align: center;
    padding: px2rem(20px) 0;
    margin-right: px2rem(68px);
  }

  &__input {
    color: #C40808;
    margin-top: px2rem(24px);
    width: 60% !important;
    font-size: px2rem(32px);
    height: px2rem(133px);

    :deep(.el-input__inner) {
      margin-left: px2rem(48px);
      height: px2rem(133px);
      color: #C40808;
      border: none;
    }

    &-icon {
      width: px2rem(42px);
      height: px2rem(42px);
      margin-top: px2rem(45px);
    }
  }

  &__tips {
    &--container {
      font-size: px2rem(28px);
      line-height: px2rem(38px);
      margin-left: px2rem(48px);
      margin-top: px2rem(30px);
      display: flex;
    }

    &-title {
      margin-top: px2rem(10px);
    }

    &-item--container {
      flex: 1;
    }
    &-item {
      display: inline-block;
      border: 1px solid #787878;
      color: #787878;
      border-radius: 10px;
      padding: px2rem(10px);
      margin-right: px2rem(20px);
      margin-bottom: px2rem(10px);
      cursor: pointer;
    }
  }
  &__button {
    margin-left: px2rem(48px);
    display: inline-block;
    font-size: px2rem(36px);
    text-align: center;
    height: px2rem(68px);
    padding: 0 px2rem(76px);
    line-height: px2rem(68px);
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 34px;
    border: 1px solid #FF5F28;
    color: #FF5F28;
    margin-top: px2rem(48px);
    margin-bottom: px2rem(42px);
  }
}

.question-answer {
  &__bottom {
    position: absolute;
    bottom: px2rem(80px);
    left: px2rem(125px);
    right: px2rem(125px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: px2rem(40px);
  }

  &__button {
    cursor: pointer;
    color: #C40808;
    flex: 1;
    height: px2rem(80px);
    line-height: px2rem(80px);
    background: white;
    border-radius: 50px;
    margin: 0 px2rem(50px);
    font-weight: bold;

    &-icon {
      position: relative;
      top: px2rem(5px);
      width: px2rem(37px);
      vertical-align: text-bottom;
      margin-right: px2rem(20px);
    }
  }

  &__button.active {
    box-shadow: 0 0 12px #0000001A;
  }

  &__input {
    height: px2rem(80px);
    margin: 0 px2rem(50px);

    :deep(.el-input__inner) {
      height: px2rem(80px);
    }
  }

  &__img {
    &--recorder {
      position: absolute;
    }

    &--clear {
      width: px2rem(64px);
      height: px2rem(64px);
      cursor: pointer;
    }

    &--micro-phone,
    &--micro-phone-disabled {
      width: px2rem(95px);
      height: px2rem(95px);
      cursor: pointer;
    }

    &--keyboard {
      width: px2rem(42px);
      height: px2rem(42px);
      cursor: pointer;
    }
  }
}

.meeting {
  &__summary-container {
    width: 80%;
    margin: auto;
    padding: px2rem(90px) 0;
    text-align: left;
  }

  &__summary-title {
    font-weight: 600;
    font-size: px2rem(42px);
    padding-bottom: px2rem(40px);
    color: #C40808;
  }
}

.step__icon {
  width: px2rem(32px);
  height: px2rem(32px);
}

.question-answer__button {
  cursor: pointer;
  color: #C40808;
  flex: 1;
  height: 5.33333rem;
  line-height: 5.33333rem;
  background: white;
  border-radius: 50px;
  margin: 0 2.08333rem;
  font-weight: bold;
  font-size: 1.5rem;
}

.is-finish .step__icon {
  background: linear-gradient(219deg, #FF964F 0%, #FF5D27 100%);;
}

.container {
  height: calc(100% - 37px);
}

.record-container {
  width: px2rem(2000px);
  margin: auto;
  height: calc(100% - 37px);
  padding-top: px2rem(37px);
}

:deep(.el-checkbox-group) {
  display: flex;
  flex-direction: column;
}

:deep(.el-checkbox__input.is-checked+.el-checkbox__label), {
  color: #C40808;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #C40808;
  border-color: #C40808;
}

:deep(.el-step__icon.is-text) {
  border: none;
  background: transparent;
}

:deep(.el-step__main) {
  position: relative;
  margin-bottom: px2rem(38px);
}

:deep(.el-step__line) {
  display: none;
}

:deep(.el-step__title) {
  font-size: px2rem(42px);
  padding-left: px2rem(48px);
}

:deep(.el-step__title.is-process), :deep(.el-step__title.is-finish) {
  color: #C40808;
  font-weight: bold;
}
</style>
