import {doRequest, GPT_REQUEST} from "@/utils/http";

export const createMeeting = (data) => {
    return doRequest(GPT_REQUEST.post(`/Jingan/Meeting`, data))
}
export const getAllMeeting = (params) => {
    return doRequest(GPT_REQUEST.get(`/Jingan/Meeting`, {params}))
}

export const getMeetingById = (id) => {
    return doRequest(GPT_REQUEST.get(`/Jingan/Meeting/${id}`))
}
export const startMeeting = (id) => {
    return doRequest(GPT_REQUEST.put(`/Jingan/Meeting/Start/${id}`))
}

export const uploadRecord = (id) => {
    return doRequest(GPT_REQUEST.post(`/Jingan/Meeting/Recording/${id}`))
}

export const closeMeeting = (id) => {
    return doRequest(GPT_REQUEST.put(`/Jingan/Meeting/Done/${id}`))
}

export const deleteMeeting = (id) => {
    return doRequest(GPT_REQUEST.delete(`/Jingan/Meeting/${id}`))
}

export const setMeetingRequest = (id) => {
    return doRequest(GPT_REQUEST.post(`/Jingan/Meeting/Analyze/${id}`))
}

export const startTask = () => {
    return doRequest(GPT_REQUEST.post(`/Jingan/Meeting/Tasks`))
}
