<template>
  <div class="chat">
    <questionAnswer></questionAnswer>
  </div>
</template>

<script >
import QuestionAnswer from "@/components/questionAnswer.vue";

export default {
  name: "chat",
  components: {QuestionAnswer},
}
</script>

<style scoped lang="scss">
.chat {
  width: 100%;
  height: 100%;
}
</style>
