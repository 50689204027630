<template>
  <div class="login-btn">
    <div class="btn-login" @click="emitLogin">开启智慧会议</div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'loginBtn',
  props: {
    isLogin: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {}
  },
  methods: {
    emitLogin() {
      this.$emit('login')
      this.$router.push({path: '/nav', replace: true})
    }
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.btn-login {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 13%;
  line-height: 10%;
  background: #FF5D27;
  box-shadow: 3px 3px 7px 0 rgba(117, 0, 0, 0.21);
  border-radius: 170px;
  color: #fff;
  font-size: 40px;
  cursor: pointer;

  &:hover {
    box-shadow: 3px 3px 7px 5px rgba(117, 0, 0, 0.21);
  }
}
</style>
