<template>
  <div class="home">
    <div class="welcome" v-if="!login">
      <img class="welcomeBg"  src="@/assets/chatRoom_bg.png" alt="">

      <div class="login__form">
        <div class="login__title">账号登录</div>

        <div>
          <div class="login__label">账号</div>
          <el-input v-model="user" class="login__input" ></el-input>
        </div>

        <div>
          <div class="login__label">密码</div>
          <el-input v-model="password" class="login__input" type="password"></el-input>
        </div>

        <div class="login__button" @click="onLogin">登录</div>
      </div>

      <div class="ICP"><a href="http://beian.miit.gov.cn/">沪ICP备2023012601号-2</a></div>
    </div>
  </div>
</template>

<script>
import loginBtn from '@/components/loginBtn.vue'
import store from "@/store/store";
import {login} from "@/service/common.service";

export default {
  name: "home-page",
  components: {loginBtn},
  data() {
    return {
      user: '',
      password: ''
    }
  },
  computed: {
    login() {
      return store.state.login
    }
  },
  methods: {
    onLogin() {
      login({
        userName: this.user,
        password: this.password
      })
          .then(res => {
            if (res['status']) {
              store.commit('loginSuccess')
              localStorage.setItem('user', JSON.stringify(res.data))
              this.$router.push({path: '/nav', replace: true})
            }
          })
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem('user'))

    if (!user) {
      return
    }

    if (user.userName) {
      store.commit('loginSuccess')
      this.$router.push({path: '/nav', replace: true})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/style/px2rem";
.home {
  width: 100%;
  height: 100%;
}
.welcome {
  height: 100%;
  width: 100%;
  overflow: hidden;

  img {
    &.welcomeBg {
      width: calc(100vw + 4px);
    }
  }
}

.login {
  &__form {
    padding: px2rem(70px) px2rem(70px) px2rem(60px) px2rem(70px);
    position: absolute;
    right: 15%;
    top: 50%;
    transform: translate(0, -50%);
    width: 30%;
    height: 50%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.9) 100%);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    //opacity: 0.9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    font-size: px2rem(48px);
    font-weight: 600;
    color: #C40808;
  }

  &__label {
    text-align: left;
    color: #C40808;
    font-size: px2rem(24px);
    margin-bottom: px2rem(23px);
  }

  &__input {
    height: px2rem(80px);
  }

  &__button {
    font-size: px2rem(36px);
    color: #FFFFFF;
    height: px2rem(80px);
    line-height: px2rem(80px);
    background: linear-gradient(243deg, #FF964F 0%, #FF5D27 100%);
    border-radius: 50px;
    cursor: pointer;
  }
}

:deep(.el-input__inner) {
  height: px2rem(80px);
}

.ICP {
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  a {
    color: white;
    text-decoration: none;
  }
}
</style>
