<template>
  <div class="meeting">
    <div class="meeting__title">{{ info.subject }}</div>
    <el-row>
      <el-col :span="12">
        <div class="meeting__info">时间：{{ info.meetingTime }}</div>
        <div class="meeting__info">地点：{{ info.location }}</div>
      </el-col>
      <el-col :span="12">
        <div class="meeting__info">应到人数：{{ info.checkInCount || '-' }}</div>
      </el-col>
    </el-row>

    <img alt="" class="meeting__arrow" src="../assets/common/meetingArrow.png" @click="goMeeting">
  </div>
</template>
<script>
export default {
  name: "meetingSummary",
  props: {
    info: {
      type: Object
    }
  },
  methods: {
    goMeeting() {
      this.$emit('onClick', this.info.id)
    }
  }
}
</script>


<style lang="scss" scoped>
@import "src/style/px2rem";

.meeting {
  position: relative;
  padding: px2rem(36px) px2rem(40px) px2rem(20px) px2rem(40px);
  background: white;
  margin-bottom: px2rem(40px);
  color: #DD1111;

  &__title {
    font-size: px2rem(36px);
    font-weight: 500;
    margin-bottom: px2rem(24px);
  }

  &__info {
    margin-bottom: px2rem(16px);
    font-size: px2rem(32px);
    color: #FE7676;
  }

  &__arrow {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: px2rem(90px);
    width: px2rem(88px);
    height: px2rem(88px);
  }
}
</style>
