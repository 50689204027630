<template>
  <div class="party-nav">
    <div class="back" @click="back">回首页</div>
    <div class="party-content">
      <div class="party__top-container">
        <div class="party-nav__item dangyuan" @click="navigate('partyMember')">
          <div class="party-nav__item-title">党员大会</div>
        </div>
        <div class="party-nav__item dangzhibu" @click="navigate('partyBranch')">
          <div class="party-nav__item-title red">党支部委员会</div>
        </div>
      </div>
      <div class="party__bottom-container">
        <div class="party-nav__item xiaozu" @click="navigate('partyGroup')">
          <div class="party-nav__item-title red">党小组会</div>
        </div>
        <div class="party-nav__item dangke" @click="navigate('partyLesson')">
          <div class="party-nav__item-title">党课</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "partynav",
  methods: {
    back() {
      this.$router.back()
    },
    navigate(route) {
      this.$router.push({path: `/${route}`})
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/style/px2rem";

.party-nav {
  text-align: left;
  padding: px2rem(85px);
  width: 100%;
  height: 100%;
  background: linear-gradient(225deg, #B00807 0%, rgba(255, 0, 0, 0.39) 100%), url('@/assets/partynav_bg.png');

  &__item {
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    border-radius: 10px;

    &-title {
      padding-top: px2rem(74px);
      text-align: center;
      font-size: px2rem(72px);
      font-weight: 800;
      color: rgba(255, 255, 255, 0.9);

      &.red {
        color: #F01E1E;
      }
    }
  }

  .dangyuan {
    background-image: url('@/assets/dangyuan.png');
    margin-right: px2rem(40px);
  }

  .dangzhibu {
    background-image: url('@/assets/dangzhibu.png');
    margin-left: px2rem(40px);
  }

  .xiaozu {
    background-image: url('@/assets/xiaozu.png');
    margin-right: px2rem(40px);
  }

  .dangke {
    background-image: url('@/assets/dangke.png');
    margin-left: px2rem(40px);
  }
}

.party-content {
  width: 80%;
  height: 100%;
  margin: auto;
}

.party {
  &__top-container {
    height: 50%;
    display: flex;
    padding-bottom: px2rem(40px);
  }

  &__bottom-container {
    height: 50%;
    display: flex;
    padding-top: px2rem(40px);
  }
}
</style>
