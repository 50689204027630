<template>
  <div class="middle-container">
    <div class="top-content">
      <!--      <el-button v-if="!recording " class="recorder" @click="audioRecord">开始语音记录</el-button>-->
      <div class="recording">
        <el-button class="record-cancel" v-if="recording && status === 'start'" @click="audioRecordPause">暂停
        </el-button>
        <el-button class="record-cancel" v-if="!recording && status === 'pause'" @click="audioResume">继续</el-button>
        <el-button class="record-cancel" v-if="!status || status === 'uploading'" @click="deleteMeeting">删除
        </el-button>
        <el-button class="record-save" v-if="recording && status === 'start' || !recording && status === 'pause'"
                   @click="audioRecordSave">结束录制
        </el-button>
        <el-button class="record-save record-save--disable"
                   v-if="(!recording && status === 'uploading') || (!recording && status === '')"
        >会议已结束
        </el-button>
        <div class="record-info">
          <span class="record-title">{{ title }}</span>
          <div class="record-sound-wave">
          </div>
          <div class="record-time">
            <span class="hour">{{ audioInfo.hour }}h</span>
            <span class="minute">&nbsp; {{ audioInfo.minute }}min</span>
            <span class="second">&nbsp; {{ audioInfo.second }}s</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-content">
      <div class="record-transition">
        <h3 class="content-title">语音记录：</h3>
        <p ref="transitionText">{{ audioInfo.transition }}</p>
      </div>
      <div class="record-ai-analysis">
        <h3 class="content-title">{{ status === 'uploading' ? 'AI转译中：预计10分钟后完成' : 'AI转译：' }}
          <i v-if="status === 'uploading'" class="el-icon-loading"></i></h3>
        <div v-if="status === ''" class="content-send" @click="openSummaryDialog">发送会议记录</div>
        <p v-html="audioInfo.aiAnswer"></p>
      </div>
    </div>
    <in-time-recorder class="question-answer__img--recorder" :status="status" :show-wave="true"
                      @message="onMessage" :meetingId="meetingInfo['meetingId']"></in-time-recorder>
    <common-dialog :show="stopRecordDialog" @onConfirm="stopRecord" @onClose="closeStopDialog">
      <div class="dialog__content">
        确认结束此次会议吗？
      </div>
    </common-dialog>
    <common-dialog :show="showSummeryDialog" :width="'80%'"
                   title="党员大会会议记录" :show-close="true"
                   :confirmText="'发送会议记录'" @onConfirm="sendPDF"
                   @onClose="closeSummaryDialog">
      <div class="summary__content">
        <div class="summary__item summary__item--bg">
          <div class="summary__container">
            <div class="summary__label">时间：</div>
            <div class="summary__value">{{ moment(meetingInfoFromAPI?.time).format('YYYY年MM月DD日') || '-' }}</div>
          </div>
          <div class="summary__container">
            <div class="summary__label">地点：</div>
            <div class="summary__value">{{ meetingInfoFromAPI?.location || '-' }}</div>
          </div>
        </div>
        <div class="summary__item">
          <div class="summary__container">
            <div class="summary__label">应到人数：</div>
            <div class="summary__value">{{meetingInfoFromAPI?.checkInCount || '-'}}</div>
          </div>
          <div class="summary__container">
            <div class="summary__label">实到人数：</div>
            <div class="summary__value">{{meetingInfoFromAPI?.absenteeCount && meetingInfoFromAPI?.checkInCount ? Number(meetingInfoFromAPI?.checkInCount) - Number(meetingInfoFromAPI?.absenteeCount) : '-'}}</div>
          </div>
        </div>
        <div class="summary__item summary__item--bg">
          <div class="summary__label">缺席者及原因：</div>
          <div class="summary__value">{{meetingInfoFromAPI?.absentees || '-'}}</div>
        </div>
        <div class="summary__item">
          <div class="summary__label">内容摘要：</div>
          <div class="summary__value">{{meetingInfoFromAPI?.summary || '-'}}</div>
        </div>
      </div>
    </common-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import recorder from "@/components/recorder.vue";
import InTimeRecorder from "@/components/inTimeRecorder.vue";
import {
  closeMeeting,
  deleteMeeting,
  getMeetingById,
  setMeetingRequest,
  startMeeting,
  startTask
} from "@/service/meeting.service";
import CommonDialog from "@/components/commonDialog.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'middleContent',
  props: {
    startRecord: Boolean,
    meetingInfo: Object
  },
  watch: {
    startRecord(val) {
      if (val) {
        this.audioRecord()
      }
    },
    meetingInfo(val) {
      const {meetingId} = val

      if (meetingId) {

        this.getMeetingInfo(meetingId, true)
      }
    }
  },
  components: {
    CommonDialog,
    InTimeRecorder,
    recorder
  },
  data() {
    return {
      status: '',
      recording: false,
      stopRecordDialog: false,
      showSummeryDialog: false,
      timeInterval: null,
      getMeetingInterval: null,
      meetingSummary: null,
      meetingInfoFromAPI: null,
      moment,
      title: '',
      startTIme: '',
      durance: '',
      audioInfo: {
        wave: '',
        hour: '-',
        minute: '-',
        second: '-',
        transition: '',
        aiAnswer: ''
      }
    }
  },
  methods: {
    getMeetingInfo(meetingId, isStart = false) {
      getMeetingById(meetingId)
          .then(res => {
            console.log(res, 123)
            this.title = res.subject
            this.meetingSummary = res.summary
            this.meetingInfoFromAPI = { ...res }
            this.setAIContent({
              ...res
            });
            if (isStart) {
              return startMeeting(meetingId)
            }
          })
          .then(res => {
            // console.log(res, 456)
          })
    },
    audioRecord() {
      this.recording = true
      this.status = 'start'
      this.startTIme = new Date().getTime()
      this.computedTime(this.startTIme);
      this.timeInterval = setInterval(() => {
        this.computedTime(this.startTIme);
      }, 1000)
    },
    audioResume() {
      this.recording = true;
      this.status = 'start';
      this.startTIme = new Date().getTime() - this.durance
      this.durance = ''
      this.timeInterval = setInterval(() => {
        this.computedTime(this.startTIme);
      }, 1000)
    },
    audioRecordSave() {
      this.stopRecordDialog = true
      this.getMeetingInfo(this.meetingInfo['meetingId'])
      this.getMeetingInterval = setInterval(() => {
        this.getMeetingInfo(this.meetingInfo['meetingId'])
      }, 1000 * 10)
    },
    stopRecord() {
      this.status = 'uploading'
      setInterval(() => {
        if (this.meetingSummary) {
          this.status = '';
        }
      }, 1000)
      this.recording = false;
      this.closeStopDialog()
      closeMeeting(this.meetingInfo['meetingId'])
          .then(res => {
            return startTask()
          })
      if (this.timeInterval) {
        clearInterval(this.timeInterval)
      }
    },
    deleteMeeting() {
      deleteMeeting(this.meetingInfo['meetingId'])
          .then(res => {
            this.$router.back()
          })
    },
    closeStopDialog() {
      this.stopRecordDialog = false;
    },
    openSummaryDialog() {
      this.showSummeryDialog = true;
    },
    closeSummaryDialog() {
      this.showSummeryDialog = false;
    },
    audioRecordPause() {
      this.recording = false
      this.status = 'pause'
      this.durance = new Date().getTime() - this.startTIme

      if (this.timeInterval) {
        clearInterval(this.timeInterval)
      }
    },
    audioRecordCancel() {
      this.recording = false
      this.status = ''
      this.startTIme = '';
      if (this.timeInterval) {
        clearInterval(this.timeInterval)
      }

    },
    computedTime(startTime) {
      let nowTime = new Date().getTime()
      let difference = nowTime - startTime,
          days = Math.floor(difference / (24 * 3600 * 1000)), //计算出相差天数
          leave1 = difference % (24 * 3600 * 1000), //计算天数后剩余的毫秒数
          hours = Math.floor(leave1 / (3600 * 1000)), //计算相差分钟数
          leave2 = leave1 % (3600 * 1000), //计算小时数后剩余的毫秒数
          minutes = Math.floor(leave2 / (60 * 1000)), //计算相差秒数
          leave3 = leave2 % (60 * 1000), //计算分钟数后剩余的毫秒数
          seconds = Math.round(leave3 / 1000);
      this.audioInfo = {
        ...this.audioInfo,
        second: seconds,
        minute: minutes,
        hour: hours,
      }
    },
    onMessage(word) {
      this.audioInfo['transition'] = this.audioInfo['transition'] + word;
      this.scrollToBottom();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const transitionText = this.$refs.transitionText;
        if (transitionText) {
          transitionText.scrollTop = transitionText.scrollHeight;
        }
      });
    },
    setAIContent(content) {
      const {subject, meetingTime, location, host, participants, checkInCount, recorder, summary} = content
      this.audioInfo.aiAnswer = `
          <div class="AIContent__subject">${subject || '-'}</div>
          <div>时间：${moment(meetingTime).format('YYYY年MM月DD日')}</div>
          <div>地点：${location || '-'}</div>
          <div>参会人员：${participants || '-'}</div>
          <div>主持人：${host || '-'}</div>
          <div>签到人数：${checkInCount || '-'}</div>
          <div>会议记录：${recorder || '-'}</div>
          <div>结论：${summary || '-'}</div>
        `
    },
    sendPDF() {
      if (this.getMeetingInterval) {
        clearInterval(this.getMeetingInterval);
      }

      setMeetingRequest(this.meetingInfo['meetingId']);
      this.$message({
        message: '发送成功',
        type: 'success'
      });
      this.showSummeryDialog = false
    }
  },
  mounted() {
    document.onkeydown = (e) => {
      let e1 = e || event || window.event || arguments.callee.caller.arguments[0]

      if (e1 && e1.keyCode && e1.keyCode === 32) {
        if (this.recording && this.status === 'start') {
          this.audioRecordPause();
        } else if (!this.recording && this.status === 'pause') {
          this.audioResume();
        }
      }
    }
  },
  beforeDestroy() {
    this.audioRecordCancel()
    if (this.getMeetingInterval) {
      clearInterval(this.getMeetingInterval);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "src/style/px2rem";

.middle-container {
  position: relative;
  width: 100%;
  height: 100%;

  .top-content {
    height: 30%;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    border-radius: px2rem(8px);
    margin: px2rem(14px) px2rem(12px) px2rem(34px) px2rem(12px);
    position: relative;

    .recorder {
      background: linear-gradient(243deg, #FF964F 0%, #FF5D27 100%);
      border-radius: px2rem(44px);
      color: #ffffff;
      width: px2rem(412px);
      height: px2rem(80px);
      font-size: px2rem(30px);
      font-weight: 400;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .recording {
      height: 100%;

      .record-save {
        width: px2rem(261px);
        height: px2rem(68px);
        background: linear-gradient(243deg, #FF964F 0%, #FF5D27 100%);
        border-radius: px2rem(34px);
        position: absolute;
        right: 16%;
        top: 20%;
        transform: translateX(50%);

        &--disable {
          background: #999999;
          color: #FFFFFF;
        }

        /deep/ span {
          width: 180px;
          font-size: px2rem(24px);
          font-weight: 400;
          color: #FFFFFF;
        }
      }

      .record-cancel {
        width: px2rem(120px);
        height: px2rem(50px);
        border-radius: px2rem(34px);
        background-image: linear-gradient(#fff, #fff), linear-gradient(234deg, rgba(255, 144, 75, 1), rgba(255, 95, 40, 1));
        background-clip: content-box, padding-box;
        border: none;
        padding: 2px;
        position: absolute;
        left: 16%;
        top: 20%;

        /deep/ span {
          font-size: px2rem(24px);
          background-image: linear-gradient(243deg, #FF964F 0%, #FF5D27 100%);;
          color: transparent;
          background-clip: text;
        }
      }

      .record-info {
        padding: px2rem(50px) 0;
        height: 100%;

        .record-sound-wave {
          height: 55%;
          width: px2rem(400px);
          margin: auto;
        }

        .record-time {
          color: #FF632B;
          height: px2rem(50px);
          font-weight: 400;
          line-height: px2rem(50px);
          font-size: px2rem(36px);
        }

        .record-label {
          font-size: px2rem(34px);
          color: #D30000;
          font-weight: 400;
          height: px2rem(50px);
          line-height: px2rem(50px);
        }
      }
    }
  }

  .bottom-content {
    height: calc(70% - 46px);
    margin: px2rem(14px) px2rem(12px);
    display: flex;

    .record-transition, .record-ai-analysis {
      background: #FFFFFF;
      border-radius: 8px;
      border: 1px solid #E1E1E1;
      height: calc(100% - 2rem);
      width: calc(50% - 11px);
      text-align: left;
      padding: px2rem(40px) px2rem(74px);

      h3 {
        font-size: px2rem(32px);
        font-weight: 500;
        color: #333333;
      }

      p {
        font-size: px2rem(32px);
        font-weight: 400;
        color: #999999;
        height: calc(100% - 70px);
        overflow: auto;
      }
    }

    .record-ai-analysis {
      position: relative;
      margin-left: px2rem(34px);
    }
  }
}

.record-title {
  color: #D30000;
  font-size: px2rem(42px);
}

.content-title {
  font-weight: bold !important;
}

.content-send {
  color: #FF5D27;
  font-size: px2rem(32px);
  position: absolute;
  right: px2rem(74px);
  top: px2rem(70px);
  cursor: pointer;
  //float: right;
}

.dialog__content {
  color: #C40808;
  font-size: px2rem(38px);
  text-align: center;
}

.summary {
  &__content {
    font-size: px2rem(32px);
  }

  &__container {
    display: flex;
    width: 50%;
    align-items: center;
  }

  &__item {
    padding: px2rem(14px);
    display: flex;

    &--bg {
      background: rgba(255, 103, 103, 0.30);
      display: flex;
    }
  }

  &__label {
    width: px2rem(250px);
    white-space: nowrap;
    color: #666666;
  }
  &__value {
    color: #999999;
  }
}

</style>
<style lang="scss">
.AIContent__subject {
  color: #333333;
  font-weight: 500;
  margin-bottom: px2rem(17px);
}
</style>
