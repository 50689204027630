<template>
  <div class="custom-dialog">
    <el-dialog
        :title="title"
        :visible.sync="show"
        :modal="model"
        :show-close="false"
        width="400px">
      <span class="form__label">请输入您的姓名</span>
      <el-input v-model="data.name" placeholder=""></el-input>

      <span class="form__label">请留下您的手机号</span>
      <el-input v-model="data.phone" placeholder=""></el-input>


      <span class="form__label">所在党组织</span>
      <el-input v-model="data.party" placeholder=""></el-input>


      <span class="form__label">或直接联系</span>
      <div class="form__label form__label--large">张老师&nbsp;54361571</div>

      <div slot="footer" class="dialog-footer">
        <div class="dialog-footer__button" @click="close">取消</div>
        <div class="dialog-footer__button dialog-footer__button--primary" @click="confirm">确认</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {createSchedule} from "@/service/common.service";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'customDialog',
  props: {
    title: {
      type: String,
      default: '党课预约'
    },
    show: {
      type: Boolean,
      default: false
    },
    model: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: '1001'
    }
  },
  data() {
    return {
      data: {
        name: '',
        phone: '',
        party: ''
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      createSchedule({
        applyType: this.type,
        userName: this.data.name,
        userPhone: this.data.phone,
        dangzuzhi: this.data.party,
        note: "string"
      })
          .then(res => {
            this.close();
          })
    }
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "src/style/px2rem";

.form__label {
  color: #C40808;
  font-size: px2rem(24px);

  &--large {
    font-size: px2rem(28px);
  }
}

.dialog-footer {
  display: flex;
  justify-content: space-between;

  &__button {
    cursor: pointer;
    border-radius: 50px;
    background: white;
    border: 1px solid #FF5D27;
    color: #FF5D27;
    padding: px2rem(6px) px2rem(84px);

    &--primary {
      color: white;
      background: linear-gradient(243deg, #FF964F 0%, #FF5D27 100%);;
    }
  }
}

:deep(.el-input) {
  margin: px2rem(14px) 0;
}

:deep(.el-dialog__body) {
  text-align: left;
}

:deep(.el-dialog__header),
:deep(.el-dialog__body),
:deep(.el-dialog__footer) {
  background: #FFF2F2;
}

//:deep(.el-dialog__wrapper) {
//  pointer-events: none;
//}
:deep(.el-dialog__title) {
  color: #C40808;
  font-weight: bold;
  font-size: px2rem(32px);
}

</style>
