<template>
  <div>

  </div>
</template>

<script>
import * as signalR from "@microsoft/signalr";
import Recorder from 'recorder-core/recorder.wav.min'
import 'recorder-core/src/engine/pcm'
import 'recorder-core/src/extensions/waveview'
import 'recorder-core/src/extensions/wavesurfer.view.js'
import {getWebSocketUrl} from "@/utils/keda";
import {hubUrl, token} from "@/utils/config";

export default {
  name: "inTimeRecorder",
  props: {
    status: {
      type: String,
      default: ''
    },
    showWave: {
      type: Boolean,
      default: false
    },
    autoStop: {
      type: Boolean,
      default: false
    },
    meetingId: {
      type: [String, Number]
    }
  },
  watch: {
    status(newVal) {
      if (newVal === 'start') {
        this.start();
      } else {
        this.stop();
      }
    },
    time(val) {
      if (val > 5000 && this.autoStop) {
        this.$emit('onStop')
      }
    },
    meetingId() {
      if (this.meetingId) {
        this.initBEConnection()
      }
    }
  },
  data() {
    return {
      connection: null,
      BEConnection: null,
      resultText: "",
      resultTextTemp: "",
      rec: null,
      testSampleRate: 16000,
      testBitRate: 16,
      SendFrameSize: 1280,
      SendInterval: 300,
      realTimeSendTryType: 'wav',
      realTimeSendTryTime: 0,
      realTimeSendTryEncBusy: null,
      realTimeSendTryNumber: null,
      transferUploadNumberMax: null,
      realTimeSendTryChunk: null,
      realTimeSendTryChunks: null,
      wave: null,
      time: 0,
      interval: null
    }
  },
  methods: {
    initRecord() {
      if (this.rec) {
        this.rec.close();
      }
      this.rec = Recorder({
        type: "unknown"
        , onProcess: (buffers, powerLevel, bufferDuration, bufferSampleRate) => {
          if (this.showWave) {
            if (this.wave) {
              this.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
            } else {
              this.wave = Recorder.WaveSurferView({elem: ".record-sound-wave"});
            }
          }
          // Runtime.Process.apply(null, arguments);

          //推入实时处理，因为是unknown格式，buffers和rec.buffers是完全相同的，只需清理buffers就能释放内存。
          this.RealTimeSendTry(buffers, bufferSampleRate, false);
        }
      });

      var t = setTimeout(() => {
        console.log("无法录音：权限请求被忽略（超时假装手动点击了确认对话框）", 1);
      }, 8000);

      this.rec.open(() => {//打开麦克风授权获得相关资源
        clearTimeout(t);

        this.RealTimeSendTryReset();//重置环境，开始录音时必须调用一次
      }, (msg, isUserNotAllow) => {
        clearTimeout(t);
        console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg, 1);
      });
    },
    initBEConnection() {
      this.BEConnection = new signalR.HubConnectionBuilder()
          .withAutomaticReconnect()//断线自动重连
          .withUrl(hubUrl, {accessTokenFactory: () => token})//传递参数Query["access_token"]
          .build();

      this.BEConnection.on("ReceiveMessage", message => {
        console.log(message, 'ReceiveMessage');
      });
      try {
        this.BEConnection.start()
      } catch (err) {
        console.error(err)
        this.$message('网络错误，请刷新后重试');
      }
    },
    initConnection() {
      return new Promise((resolve, reject) => {
        const websocketUrl = getWebSocketUrl();
        if ("WebSocket" in window) {
          this.connection = new WebSocket(websocketUrl);
        } else if ("MozWebSocket" in window) {
          this.connection = new MozWebSocket(websocketUrl);
        } else {
          return;
        }

        this.connection.onopen = (e) => {
          // var params = {
          //   common: {
          //     app_id: APPID,
          //   },
          //   business: {
          //     language: "zh_cn",
          //     domain: "iat",
          //     accent: "mandarin",
          //     vad_eos: 5000,
          //     dwa: "wpgs",
          //   },
          //   data: {
          //     status: 0,
          //     format: "audio/L16;rate=16000",
          //     encoding: "raw",
          //   },
          // };
          // this.connection.send(JSON.stringify(params));
          resolve('success')
        };
        this.connection.onmessage = (e) => {
          console.log(e, 12222333)
          this.renderResult(e.data);
        };
        this.connection.onerror = (e) => {
          console.error(e);
          this.$emit('onStop')
          reject('error')
          // this.initConnection()
        };
        this.connection.onclose = (e) => {
          console.error(e)
          this.$emit('onStop')
          reject('close')
          // this.initConnection()
        };
      })

    },
    renderResult(resultData) {
      if (this.status === 'start') {
        this.time = 0
      }
      const _data = JSON.parse(resultData)
      // 识别结束
      let data = _data['result'];
      let result = ''
      if (!data) {
        return
      }
      result = data['voice_text_str']
      if (data['slice_type'] === 2) {
        this.$emit('message', result)
        result = ''
      }
    },

    async start() {
      if (this.rec) {
        this.rec.close();
      }
      if (this.autoStop) {
        this.interval = setInterval(() => {
          this.time = this.time + 100
        }, 100)
      }
      let result
      try {
        result = await this.initConnection()
      } catch (e) {
        this.$message('网络错误，请刷新后再试');
        console.error(e)
      }

      if (result !== 'success') {
        return
      }

      this.rec = Recorder({
        type: "unknown"
        , onProcess: (buffers, powerLevel, bufferDuration, bufferSampleRate) => {
          if (this.showWave) {
            if (this.wave) {
              this.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
            } else {
              this.wave = Recorder.WaveSurferView({elem: ".record-sound-wave"});
            }
          }
          this.$emit('onRecording')
          //推入实时处理，因为是unknown格式，buffers和rec.buffers是完全相同的，只需清理buffers就能释放内存。
          this.RealTimeSendTry(buffers, bufferSampleRate, false);
        }
      });

      let t = setTimeout(() => {
      }, 8000);

      this.rec.open(() => {//打开麦克风授权获得相关资源
        clearTimeout(t);
        this.rec.start();//开始录音
        if (this.showWave) {
          this.wave = Recorder.WaveView({elem: ".record-sound-wave"});
        }

        this.RealTimeSendTryReset();//重置环境，开始录音时必须调用一次
      }, (msg, isUserNotAllow) => {
        clearTimeout(t);
      });
    },

    stop() {
      if (this.interval) {
        this.time = 0
        clearInterval(this.interval)
      }

      this.rec.close();//直接close掉即可，这个例子不需要获得最终的音频文件
      this.connection.send(JSON.stringify({"end": true}));
      this.RealTimeSendTry([], 0, true);//最后一次发送
      this.connection.close();
    },

    RealTimeSendTry(buffers, bufferSampleRate, isClose) {
      let t1 = Date.now();
      if (this.realTimeSendTryTime == 0) {
        this.realTimeSendTryTime = t1;
        this.realTimeSendTryEncBusy = 0;
        this.realTimeSendTryNumber = 0;
        this.transferUploadNumberMax = 0;
        this.realTimeSendTryChunk = null;
      }
      if (!isClose && t1 - this.realTimeSendTryTime < this.SendInterval) {
        return;//控制缓冲达到指定间隔才进行传输
      }

      this.realTimeSendTryTime = t1;
      let number = ++this.realTimeSendTryNumber;

      let pcm = [], pcmSampleRate = 0;
      if (buffers.length > 0) {
        //借用SampleData函数进行数据的连续处理，采样率转换是顺带的，得到新的pcm数据
        let chunk = Recorder.SampleData(buffers, bufferSampleRate, this.testSampleRate, this.realTimeSendTryChunk, {frameType: isClose ? "" : this.realTimeSendTryType});

        //清理已处理完的缓冲数据，释放内存以支持长时间录音，最后完成录音时不能调用stop，因为数据已经被清掉了
        for (let i = this.realTimeSendTryChunk ? this.realTimeSendTryChunk.index : 0; i < chunk.index; i++) {
          buffers[i] = null;
        }
        this.realTimeSendTryChunk = chunk;//此时的chunk.data就是原始的音频16位pcm数据（小端LE），直接保存即为16位pcm文件、加个wav头即为wav文件、丢给mp3编码器转一下码即为mp3文件

        pcm = chunk.data;
        pcmSampleRate = chunk.sampleRate;
      }

      //没有新数据，或结束时的数据量太小，不能进行mock转码
      if (pcm.length == 0 || isClose && pcm.length < 2000) {
        this.TransferUpload(number, null, 0, null, isClose);
        return;
      }

      //实时编码队列阻塞处理
      if (!isClose) {
        if (this.realTimeSendTryEncBusy >= 2) {
          console.log("编码队列阻塞，已丢弃一帧", 1);
          return;
        }
      }
      this.realTimeSendTryEncBusy++;


      //通过mock方法实时转码成mp3、wav；16位pcm格式可以不经过此操作，直接发送new Blob([pcm.buffer],{type:"audio/pcm"}) 要8位的就必须转码
      let encStartTime = Date.now();
      let recMock = Recorder({
        type: this.realTimeSendTryType
        , sampleRate: this.testSampleRate //采样率
        , bitRate: this.testBitRate //比特率
      });
      recMock.mock(pcm, pcmSampleRate);
      recMock.stop((blob, duration) => {
        this.realTimeSendTryEncBusy && (this.realTimeSendTryEncBusy--);
        blob.encTime = Date.now() - encStartTime;

        //转码好就推入传输
        this.TransferUpload(number, blob, duration, recMock, isClose);
      }, (msg) => {
        this.realTimeSendTryEncBusy && (this.realTimeSendTryEncBusy--);

        //转码错误？没想到什么时候会产生错误！
        this.$message('转码错误，请刷新后重试');
        console.log("不应该出现的错误:" + msg, 1);
      });
    },
    TransferUpload(number, blobOrNull, duration, blobRec, isClose) {
      this.transferUploadNumberMax = Math.max(this.transferUploadNumberMax, number);
      if (blobOrNull) {
        var blob = blobOrNull;
        var encTime = blob.encTime;

        this.connection.send(blob)

        // if (this.BEConnection) {
        // this.BEConnection.invoke("UploadBinaryAudio", this.meetingId.toString(), new Date().getTime(), blob, 'wav').catch(err => console.error(err));
        // }

        //*********发送方式一：Base64文本发送***************
        var reader = new FileReader();
        reader.onloadend = () => {
          var base64 = (/.+;\s*base64\s*,\s*(.+)$/i.exec(reader.result) || [])[1];
          if (this.BEConnection) {
            this.BEConnection.invoke("UploadBase64Audio", Number(this.meetingId), new Date().getTime(), base64, 'wav')
                .catch(err => {
                      this.$message('网络错误，请刷新后再试')
                      console.log(err)
                    }
                );
          }
          // this.connection.send(
          //     JSON.stringify({
          //       data: {
          //         status: 1,
          //         format: "audio/L16;rate=16000",
          //         encoding: "raw",
          //         audio: base64,
          //       },
          //     })
          // );
          // console.log(base64, 666)

          //可以实现
          //WebSocket send(base64) ...
          //WebRTC send(base64) ...
          //XMLHttpRequest send(base64) ...

          //这里啥也不干
        };
        reader.readAsDataURL(blob);

        //*********发送方式二：Blob二进制发送***************
        //可以实现
        // console.log(blob, 123333)

        // send(blob) ...
        //WebRTC send(blob) ...
        //XMLHttpRequest send(blob) ...


        //****这里仅 console.log一下 意思意思****
        var numberFail = number < this.transferUploadNumberMax ? '<span style="color:red">顺序错乱的数据，如果要求不高可以直接丢弃</span>' : "";
        var logMsg = "No." + (number < 100 ? ("000" + number).substr(-3) : number) + numberFail;

        // console.log(blob, duration, blobRec, logMsg + "花" + ("___" + encTime).substr(-3) + "ms");
      }

      if (isClose) {
        console.log("No." + (number < 100 ? ("000" + number).substr(-3) : number) + ":已停止传输");
      }
    },
    RealTimeSendTryReset() {
      this.realTimeSendTryChunks = null;
      this.realTimeSendTryChunk = null;
    }
  },
  mounted() {
    this.initRecord()
  },
  beforeDestroy() {
    if (this.interval) {
      this.time = 0
      clearInterval(this.interval)
    }
  }
}
</script>


<style scoped lang="scss">

</style>
