import { render, staticRenderFns } from "./sense.vue?vue&type=template&id=bca0f6a2&scoped=true&"
import script from "./sense.vue?vue&type=script&lang=js&"
export * from "./sense.vue?vue&type=script&lang=js&"
import style0 from "./sense.vue?vue&type=style&index=0&id=bca0f6a2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bca0f6a2",
  null
  
)

export default component.exports