import axios from 'axios'
import {createError} from './util'

const ip = 'https://api.smartjingandj.cn/'
const GPT_address = 'https://ai.api.1app.site/api'

// 统一配置请求
const HTTP_REQUEST = axios.create({
    baseURL: ip,
    validateStatus(status) {
        // 200 外的状态码都认定为失败
        return status === 200
    }
})

const GPT_REQUEST = axios.create({
    baseURL: GPT_address,
    headers: {
        Authorization : `Bearer JA5ABA9E202D94C43ASW3CA6600F2BF77FJN`
    },
    validateStatus(status) {
        return status === 200
    }
})

// 统一拦截请求
HTTP_REQUEST.interceptors.request.use((config) => {
    return config
}, (error) => {
    return Promise.reject(error)
});

// 统一拦截响应
HTTP_REQUEST.interceptors.response.use((config) => {
    return config
}, (error) => {
    return Promise.reject(error)
});

GPT_REQUEST.interceptors.request.use((config) => {
    return config
}, (error) => {
    return Promise.reject(error)
});

// 统一拦截响应
GPT_REQUEST.interceptors.response.use((config) => {
    return config
}, (error) => {
    return Promise.reject(error)
});

//定义异步请求方法,(如果返回有异常就提示信息,并抛出异常)
const doRequest = async (request) => {
    try {
        const resp = await request;
        const data = resp.data;
        if (!data) {
            if (resp.status === 201 || resp.status === 203 || resp.status === 204) {
                return ''
            }
        } else {
            return data
        }
    } catch (err) {
        const errResp = err.response;
        console.log('---------------', err);
        if (errResp.status === 404) {
            console.log('错误, 未找到数据');
            throw createError(errResp.status, "错误, 未找到数据");
        } else if (errResp.status === 401) {
            console.log('错误, 您无权访问');
            throw createError(errResp.status, '错误, 您无权访问');
        } else {
            console.log(errResp.message);
            throw err;
        }
    }
}

const streamFetch = ({url, data, method = 'GET'}) => {
    return new Promise(async (resolve, reject) => {

    const response = await fetch(GPT_address + url, {
        method: method,
        body: JSON.stringify(data),
        headers: {
            Authorization: 'Bearer JA5ABA9E202D94C43ASW3CA6600F2BF77FJN',
            "Content-Type": "application/json"
        }
    })

    const encode = new TextDecoder("utf-8");

    const reader = response.body.getReader();

    let result = '';

    while (true) {
        const { done, value } = await reader.read();
        if (done) {
            break;
        }
        // 解码内容
        const text = encode.decode(value);
        // 当获取错误token时，输出错误信息
        if (text === "<ERR>") {
            // output.innerText = "Error";
            reject('err')
            break;
        } else {
            result = result + text
        }
    }
        resolve(result)
    })
}
//导出所有请求
export {
    doRequest,
    HTTP_REQUEST,
    GPT_REQUEST,
    streamFetch
}
