<template>
  <div id="app" v-loading.fullscreen.lock="loading"  element-loading-customClass="spinner">
    <div class="button" @click="full" v-if="!login"></div>
<!--    <menuBar v-if="login"></menuBar>-->
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>

<script>

import middleContent from "@/components/middleContent.vue";
import MenuBar from "@/components/menuBar.vue";
import store from "@/store/store";

export default {
  name: 'App',

  components: {
    MenuBar
    // HelloWorld
  },
  computed: {
    login() {
      return store.state.login
    },
    loading() {
      return store.state.loading
    }
  },
  methods: {
    full() {
      const ele = document.body
      if (ele.requestFullscreen) {
        ele.requestFullscreen();
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen();
      } else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen();
      } else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen();
      }
    }
  },
  watch: {
    $route: {
      handler: function(val, oldVal){
        if (this.$route.path !== '/') {
          store.commit('loginSuccess')
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  mounted() {
    if (this.$route.path !== '/') {
      store.commit('loginSuccess')
    }
  }
}
</script>

<style lang="scss">

@import "@/style/style";
*, *:before, *:after {
  box-sizing: border-box;
}
html, body, #app {
  width: 100%;
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  //margin-top: 60px;
  //position: relative;
  //display: flex;
  //align-items: stretch;
}

.container {
  width: 100%;
  height: 100%;
}

.button {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  bottom: 0;
}
</style>
