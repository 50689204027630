<template>
  <div class="sense main-bg">
    <div class="back brown" @click="back">回首页</div>
    <div class="tab-container">
      <el-tabs v-model="activeName">
        <el-tab-pane class="tabs" label="态势感知" name="first">
          <iframe src="https://www.shdgxt.cn/vpn/user/auth/home" style="width: 100%;height: 100%"></iframe>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>

</template>

<script>

export default {
  name: "sense",
  data() {
    return {
      activeName: 'first'
    }
  },
  methods: {
    back() {
      this.$router.back()
    }
  }
}
</script>


<style scoped lang="scss">
@import "src/style/px2rem";

.sense {
  height: 100%;
  padding: px2rem(50px) px2rem(100px);
}

.tab-container {
  height: 100%;
  padding: px2rem(53px) 0;
}

:deep(.el-tabs__item.is-active) {
  color: #C40808;
  font-weight: bold;
}

:deep(.el-tabs__active-bar) {
  background-color: #C40808;
}

:deep(.el-tabs),
:deep(.el-tabs__content),
:deep(.el-tab-pane) {
  height: 100%;
}

:deep(.el-tabs__item) {
  font-size: px2rem(32px);
}
</style>
