import { doRequest, GPT_REQUEST, HTTP_REQUEST, streamFetch } from "@/utils/http";

export const getAnswer = (question) => {
    return doRequest(HTTP_REQUEST.get(`/QA/GetAnswer?question=${question}`, {
        responseType: 'text',
    }))
}

export const getGPTAnswer = (word , type, isTest = false) => {
    if (!type) {
        return
    }

    let result;

    if (isTest) {

        if (type !== 'normal') {
            result = streamFetch({url: `/Jingan/Professional/Chat`, method: 'POST', data: {question: word}})
        } else {
            result = doRequest(GPT_REQUEST.get(`/Jingan/Free/Chat?question=${word}`, {
                headers: {
                    'Authorization': 'Bearer JA5ABA9E202D94C43ASW3CA6600F2BF77FJN'
                }
            }))
        }

        return result;
    }

    if (type === 'normal') {
        result = doRequest(GPT_REQUEST.post(`/chat/send/mc`, {
            prompt: word
        },{
            headers: {
                'Authorization': 'Bearer MC5ABA9E202D94C43ASW3CA6600F2BF77FMC'
            }
        }))
    } else {
        result = doRequest(HTTP_REQUEST.get(`/QA/GetAnswer?question=${word}`, {
            responseType: 'text',
        }))
    }

    return result;

}

export const uploadRecord = (data) => {
    return doRequest(HTTP_REQUEST.post(`/QA/UploadRecord`, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }))
}

export const getAli = (id) => {
    return doRequest(HTTP_REQUEST.get(`/QA/CallAli?id=${id}`))
}

export const getWavIdByWords = (words) => {
  return doRequest(GPT_REQUEST.post(`/Jingan/TextToVoice`,{
      words: words
  }))
}

export const getWavById = (id) => {
    return doRequest(GPT_REQUEST.get(`/Jingan/TextToVoiceResult?taskId=${id}`))
}


