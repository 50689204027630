import Vue from 'vue'
import Router from 'vue-router'
import Home from "@/views/home/home.vue";
import Chat from "@/views/chat/chat.vue";
import partyMember from "@/views/partyMember/partyMember.vue";
import partyBranch from "@/views/partyBranch/partyBranch.vue";
import partyLesson from "@/views/partyLesson/partyLesson.vue";
import sense from "@/views/sense/sense.vue";
import OA from "@/views/oa/OA.vue";
import Navigator from "@/views/navigator.vue";
import partynav from "@/views/partynav.vue";

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/nav',
      name: 'nav',
      component: Navigator
    },
    {
      path: '/partynav',
      name: 'partynav',
      component: partynav
    },
    {
      path: '/chat',
      name: 'chat',
      component: Chat
    },
    {
      path: '/partyMember',
      name: 'partyMember',
      component: partyMember
    },
    {
      path: '/partyBranch',
      name: 'partyBranch',
      component: partyBranch
    },
    {
      path: '/partyGroup',
      name: 'partyGroup',
      component: partyBranch
    },
    {
      path: '/partyLesson',
      name: 'partyLesson',
      component: partyLesson
    },
    {
      path: '/sense',
      name: 'sense',
      component: sense
    },
    {
      path: '/oa',
      name: 'oa',
      component: OA
    }
  ],
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  let user = JSON.parse(localStorage.getItem('user'))

  if (!user && to.name !== 'home') {
    next({ name: 'home' })
  } else {
    next()
  }
})

export default router

