<template>
  <div class="teacher-table">
    <div class="teacher-table__top-container">
      <div class="teacher-table__title">社区党校师资库</div>
      <div class="teacher-table__button" @click="openDialog">党课咨询</div>
    </div>

    <el-table
        :data="tableData"
        stripe
        style="width: 100%">
      <el-table-column
          prop="id"
          label=""
          width="60">
      </el-table-column>
      <el-table-column
          prop="name"
          label=""
          width="120">
      </el-table-column>
      <el-table-column
          prop="title"
          label="">
      </el-table-column>
    </el-table>
    <customDialog title="党课预约" :show="showDialog" :model="true" @close="closeDialog" :type="'1001'"></customDialog>
  </div>
</template>
<script>
import CustomDialog from "@/components/customDialog.vue";

export default {
  name: "teacherTable",
  components: {CustomDialog},
  data() {
    return {
      tableData: [
        {
          "id": "1",
          "name": "肖  晋",
          "title": "中国浦东干部学院副教授、博士"
        },
        {
          "id": "2",
          "name": "何海兵",
          "title": "中共上海市委党校社会学教研部副主任、教授、博士"
        },
        {
          "id": "3",
          "name": "卜新兵",
          "title": "中共上海市委党校副教授、博士"
        },
        {
          "id": "4",
          "name": "陈荣武",
          "title": "上海市社会主义学院副教授、博士"
        },
        {
          "id": "5",
          "name": "刁含勇",
          "title": "上海市社会主义学院讲师、博士"
        },
        {
          "id": "6",
          "name": "张小帅",
          "title": "中国浦东干部学院教研部讲师"
        },
        {
          "id": "7",
          "name": "孙长来",
          "title": "中共静安区委党校党史党建教研室主任、副教授"
        },
        {
          "id": "8",
          "name": "徐振光",
          "title": "中共静安区委党校基础理论教研室主任、副教授"
        },
        {
          "id": "9",
          "name": "马  倩",
          "title": "中共静安区委党校公共管理教研室主任、讲师"
        },
        {
          "id": "10",
          "name": "左  敏",
          "title": "中共静安区委党校科研室主任、讲师"
        },
        {
          "id": "11",
          "name": "黄寿东",
          "title": "中共静安区委党校讲师"
        },
        {
          "id": "12",
          "name": "尤  玮",
          "title": "中共二大会址纪念馆副馆长"
        },
        {
          "id": "13",
          "name": "王长燕",
          "title": "原静安寺街道综合党委副书记、“支部建在楼上”亲历者"
        },
        {
          "id": "14",
          "name": "柏万青",
          "title": "上海市人大代表，静安寺街道柏万青志愿者工作室负责人、老年协会会长"
        },
        {
          "id": "15",
          "name": "王琪英",
          "title": "原静安寺街道楼宇党群工作者、工会指导员"
        }
      ],
      showDialog: false
    }
  },
  methods: {
    openDialog() {
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    }
  }
}
</script>


<style scoped lang="scss">
@import "src/style/px2rem";

.teacher-table {
  padding-top: px2rem(50px);
  padding-bottom: px2rem(30px);
  text-align: left;

  &__top-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: px2rem(23px);
  }

  &__title {
    font-size: px2rem(40px);
  }

  &__button {
    cursor: pointer;
    padding: px2rem(9px) px2rem(59px);
    background: linear-gradient(243deg, #FF964F 0%, #FF5D27 100%);
    color: white;
    border-radius: 50px;
    font-size: px2rem(30px);
  }
}

:deep(.has-gutter) {
  display: none;
}
/*最外层透明*/
:deep(.el-table), :deep(.el-table__expanded-cell) {
  background-color: transparent;
}

/* 表格内背景颜色 */
:deep(.el-table th),
:deep(.el-table tr),
:deep(.el-table td) {
  background-color: transparent;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background: rgba(255, 103, 103, 0.1);
}
</style>

<style>
/*最外层透明*/
.el-table, .el-table__expanded-cell {
  background-color: transparent;
}

/* 表格内背景颜色 */
.el-table th,
.el-table tr,
.el-table td {
  background-color: transparent;
}
</style>
