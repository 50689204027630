<template>
  <div class="common-dialog">
    <el-dialog
        :title="title"
        :visible.sync="show"
        :modal="model"
        :show-close="showClose"
        :width="width"
        @close="close">
      <slot></slot>
      <div slot="footer" class="dialog-footer" v-if="showFooter">
        <div class="dialog-footer__button" @click="close">取消</div>
        <div class="dialog-footer__button dialog-footer__button--primary" @click="confirm">{{confirmText}}</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "commonDialog",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    model: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '400px'
    },
    showClose: {
      type: Boolean,
      default: false
    },
    confirmText: {
      type: String,
      default: '确认'
    }
  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    confirm() {
      this.$emit('onConfirm');
    }
  }
}
</script>


<style lang="scss" scoped>
@import "src/style/px2rem";

.form__label {
  color: #C40808;
  font-size: px2rem(24px);

  &--large {
    font-size: px2rem(28px);
  }
}

.dialog-footer {
  display: flex;
  justify-content:center;

  &__button {
    cursor: pointer;
    border-radius: 50px;
    background: white;
    border: 1px solid #FF5D27;
    color: #FF5D27;
    padding: px2rem(6px) px2rem(84px);

    &--primary {
      color: white;
      background: linear-gradient(243deg, #FF964F 0%, #FF5D27 100%);
      margin-left: px2rem(30px);
    }
  }
}

:deep(.el-input) {
  margin: px2rem(14px) 0;
}

:deep(.el-dialog__body) {
  text-align: left;
}

:deep(.el-dialog__header),
:deep(.el-dialog__body),
:deep(.el-dialog__footer) {
  background: #FFF2F2;
}

//:deep(.el-dialog__wrapper) {
//  pointer-events: none;
//}
:deep(.el-dialog__title) {
  color: #C40808;
  font-weight: bold;
  font-size: px2rem(32px);
}

</style>
