<template>
  <div class="nav">
    <div class="nav__header">

      <div class="nav__title">
        <img class="nav__logo" src="@/assets/party_logo.png" alt="">
        静安寺街道智慧党建
      </div>
      <div class="nav__temp-container">
        <div class="nav__temperature">
          <img :src="`/weather/${weather.code}.png`" alt="">
          <span class="nav__temperature--text">{{ weather.text }}</span>
          <span>{{ weather.temperature }}°C</span>
        </div>
        <div class="nav__date">
          {{ time }}
        </div>
      </div>

    </div>
    <div class="nav__body">
      <div class="nav__item" @click="navigate('chat')">
        <div class="nav__item-title">智慧党建问答助手</div>
        <div class="nav__item-content">1.近期会议精神学习</div>
        <div class="nav__item-content">2.党务基础知识</div>
        <div class="nav__item-content">3.党章学习</div>
        <div class="nav__item-content">4.党内文件学习</div>
        <img class="nav__icon--big first" src="@/assets/nav_icon1.png" alt="">
      </div>
      <div class="nav__item--double">
        <div class="nav__item--split" style="display: flex; padding: 0">
          <div class="nav__item--left" @click="navigate('partynav')"
          :style="`background-image: url(${sense_bg})`">
            <div class="nav__item-title">“两企三新”党组织</div>
            <!--            <div class="nav__item-content">党组织活动开展情况一览</div>-->
            <!--            <img class="nav__icon&#45;&#45;small" src="@/assets/nav_icon3.png" alt="">-->
          </div>
          <div class="nav__item--right" @click="navigate('partynav')"
               :style="`background-image: url(${oa_bg})`">
            <div class="nav__item-title">驻区单位党组织</div>
            <!--            <div class="nav__item-content">数字智能化党组织工作建设</div>-->
            <!--            <img class="nav__icon&#45;&#45;small" src="@/assets/nav_icon4.png" alt="">-->
          </div>
<!--          <div class="nav__item-title">智慧三会一课指导</div>-->
<!--          <div class="nav__item-content">1.党员大会</div>-->
<!--          <div class="nav__item-content">2.党支部委员会</div>-->
<!--          <div class="nav__item-content">3.党小组会</div>-->
<!--          <div class="nav__item-content">4.党课</div>-->
<!--          <img class="nav__icon&#45;&#45;big second" src="@/assets/nav_icon2.png" alt="">-->
        </div>
        <div class="nav__item--split second">
          <div class="nav__item--left" @click="navigate('partynav')"
               :style="`background-image: url(${juminqu})`">
            <div class="nav__item-title">居民区党组织</div>
<!--            <div class="nav__item-content">党组织活动开展情况一览</div>-->
<!--            <img class="nav__icon&#45;&#45;small" src="@/assets/nav_icon3.png" alt="">-->
          </div>
          <div class="nav__item--right" @click="navigate('partynav')"
               :style="`background-image: url(${jiguanzuzhi})`">
            <div class="nav__item-title">机关党组织</div>
<!--            <div class="nav__item-content">数字智能化党组织工作建设</div>-->
<!--            <img class="nav__icon&#45;&#45;small" src="@/assets/nav_icon4.png" alt="">-->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import axios from "axios";
import {XINZHI_KEY} from "@/utils/config";
import moment from "moment";
import chat from "@/views/chat/chat.vue";
import sense_bg from "@/assets/sense_bg.png";
import oa_bg from "@/assets/oa_bg.png";
import jiguanzuzhi from "@/assets/jiguanzuzhi.png";
import juminqu from "@/assets/juminqu.png";

export default {
  name: "navigator",
  data() {
    return {
      weather: {},
      time: '',
      sense_bg,
      oa_bg,
      jiguanzuzhi,
      juminqu
    }
  },
  methods: {
    navigate(route) {
      if (route === "chat") {
        this.$router.push({path: `/${route}`, query: {isTest: true}})
      } else {
        this.$router.push({path: `/${route}`})
      }
    },
    getTemp() {
      axios
          .get(`https://api.seniverse.com/v3/weather/now.json?key=${XINZHI_KEY}&location=shanghai&language=zh-Hans&unit=c`)
          .then(res => (
              this.weather = res.data.results[0]['now']
          ))
          .catch(function (error) { // 请求失败处理
            console.log(error);
          });
    },
    getTime() {
      this.time = moment().format('MM.DD HH:mm')
      setInterval(() => {
        this.time = moment().format('MM.DD HH:mm')
      }, 1000)

    }
  },
  mounted() {
    this.getTemp()
    this.getTime()
  }
}
</script>

<style scoped lang="scss">
@import "src/style/px2rem";

.nav {
  width: 100%;
  height: 100%;
  padding: px2rem(64px) px2rem(128px) px2rem(128px) px2rem(128px);
  background-image: url("@/assets/nav_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;

  &__logo {
    vertical-align: middle;
    width: px2rem(169px);
    height: px2rem(169px);
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__temp-container {
    display: flex;
    align-items: center;
  }

  &__temperature {
    display: flex;
    align-items: baseline;
    font-size: px2rem(48px);
    margin-right: px2rem(127px);

    &--text {
      margin-right: px2rem(29px);
    }

    img {
      align-self: end;
      width: px2rem(70px);
      height: px2rem(70px);
      margin-right: px2rem(29px);
    }
  }

  &__date {
    font-size: px2rem(48px);
  }

  &__title {
    text-align: left;
    font-size: px2rem(72px);
  }

  &__body {
    height: calc(100% - 63px);
    padding-top: px2rem(70px);
    display: flex;
    text-align: left;
  }

  &__item {
    position: relative;
    cursor: pointer;
    padding: px2rem(35px) px2rem(50px);
    height: 100%;
    width: 40%;
    background-image: url('@/assets/wenda_bg.png');
    background-size: 100% 100%;
    background-position: 100% 100%;
    background-repeat: no-repeat;
  }

  &__item--double {
    margin-left: px2rem(50px);
    width: calc(100% - 50px);
    height: 100%;
  }

  &__item--split {
    cursor: pointer;
    height: calc(50% - 17px);
    padding: px2rem(45px) px2rem(52px);
    background-size: 100% 100%;
    background-position: 100% 100%;
    background-repeat: no-repeat;
  }

  &__item--split.second {

    margin-top: px2rem(50px);
    display: flex;
    padding: 0;
    border: none;
    background-image: none;
  }

  &__item--left {
    position: relative;
    width: calc(50% - 17px);
    padding: px2rem(35px) px2rem(50px);
    background-size: 100% 100%;
    background-position: 100% 100%;
    background-repeat: no-repeat;
  }

  &__item--right {
    position: relative;
    margin-left: px2rem(50px);
    width: calc(50% - 17px);
    padding: px2rem(35px) px2rem(50px);
    background-size: 100% 100%;
    background-position: 100% 100%;
    background-repeat: no-repeat;
  }

  &__icon--big {
    position: absolute;
    width: px2rem(168px);
    height: px2rem(168px);
  }

  &__icon--big.first {
    right: px2rem(50px);
    top: px2rem(140px);
  }

  &__icon--big.second {
    left: px2rem(400px);
    top: px2rem(150px);
  }

  &__icon--small {
    position: absolute;
    width: px2rem(120px);
    height: px2rem(120px);
    right: px2rem(36px);
    top: px2rem(60px);
  }
}

.nav__item-title {
  margin-bottom: px2rem(26px);
  font-size: px2rem(56px);
  font-weight: 600;
  color: #FFFFFF;
}

.nav__item-content {
  font-size: px2rem(36px);
  font-weight: 400;
  color: rgba(255, 255, 255, 0.66);
}

</style>
