import {doRequest, GPT_REQUEST, HTTP_REQUEST} from "@/utils/http";

export const getAnswer = (question) => {
    return doRequest(HTTP_REQUEST.get(`/QA/GetAnswer?question=${question}`, {
        responseType: 'text',
    }))
}

export const login = (data) => {
    return doRequest(GPT_REQUEST.post(`/Jingan/login`, data))
}

export const getActivities = () => {
    return doRequest(GPT_REQUEST.get(`/Jingan/Activities`))
}

export const createSchedule = (data) => {
    return doRequest(GPT_REQUEST.post(`/Jingan/Schedule`, data))
}
