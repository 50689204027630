<template>
  <div class="OA main-bg">
    <div class="back brown" @click="back">回首页</div>
    <div class="tab-container">
      <el-tabs v-model="activeName">
        <el-tab-pane class="tabs" label="街道办活动列表" name="first">
          <div>
            <activity-table></activity-table>
<!--            <img src="@/assets/development.svg" alt="" style="margin-top: 20px;">-->
<!--            <div style="color: #666666">功能开发中，敬请期待</div>-->
          </div>

        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ActivityTable from "@/components/activityTable.vue";

export default {
  name: "OA",
  components: {ActivityTable},
  data() {
    return {
      activeName: 'first'
    }
  },
  methods: {
    back() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/style/px2rem";

.OA {
  height: 100%;
  padding: px2rem(50px) px2rem(100px);
}

.tab-container {
  height: 100%;
  padding: px2rem(53px) 0;
}

:deep(#pane-first) {
  overflow-y: auto;
}

:deep(.el-tabs__item.is-active) {
  color: #C40808;
  font-weight: bold;
}

:deep(.el-tabs__active-bar) {
  background-color: #C40808;
}

:deep(.el-tabs),
:deep(.el-tabs__content),
:deep(.el-tab-pane) {
  height: 100%;
}

:deep(.el-tabs__item) {
  font-size: px2rem(32px);
}
</style>
