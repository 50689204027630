<template>
  <div class="">
    <!--      <div class="record-sound-wave"></div>-->
    <!--        <button @click="start()">start</button>-->
    <!--        <button @click="stop()">stop</button>-->
  </div>
</template>

<script>
import Recorder from 'recorder-core/recorder.wav.min'
import 'recorder-core/src/extensions/waveview'
import 'recorder-core/src/extensions/wavesurfer.view.js'
import Axios from 'axios'
import hmacSHA1 from 'crypto-js/hmac-sha1'
import MD5 from 'crypto-js/md5'
import Base64 from "crypto-js/enc-base64";

const CONVERT_BASE_URL = 'https://nls-gateway-cn-shanghai.aliyuncs.com'
const URL = '/stream/v1/asr'
const APP_KEY = 'NLdU3RiPOzJGWLDW'
const TOKEN = 'fe11feb171704fd2becf17e14d164063'

const IAT_URL = '/v2/api/upload'
const IAT_BASE_URL = 'https://raasr.xfyun.cn'
const IAT_APP_ID = 'b5cffd50'
const IAT_SECRET_KEY = 'bfe4ea59efd5a20cb88ea841a8b79f8a'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'recorder',
  data() {
    return {
      rec: null,
      wave: null,
      recBlob: null,
      newRec: null
    }
  },
  props: ['status', 'showWave'],
  watch: {
    status(newVal) {
      if (newVal === 'start') {
        this.start();
      } else {
        this.stop();
      }
    }
  },
  methods: {
    initRecord() {
      let that = this;
      this.newRec = Recorder({
        type: "wav", sampleRate: 8000, bitRate: 16 //mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
        , onProcess: function (buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx, asyncEnd) {
          if (that.showWave) {
            if (that.wave) {
              that.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate);
            } else {
              that.wave = Recorder.WaveSurferView({elem: ".record-sound-wave"});
            }
          }

        }
      })

      this.newRec.open(() => {//打开麦克风授权获得相关资源

        this.rec = this.newRec;

        //此处创建这些音频可视化图形绘制浏览器支持妥妥的

        console.log("已打开录音，可以点击录制开始录音了", 2);
      }, (msg, isUserNotAllow) => {//用户拒绝未授权或不支持
        // dialogCancel(); //如果开启了弹框，此处需要取消
        console.log((isUserNotAllow ? "UserNotAllow，" : "") + "打开录音失败：" + msg, 1);
      });

      window.waitDialogClick = function () {
        console.log("打开失败：权限请求被忽略");
      };
    },
    start() {
      if (this.rec && Recorder.IsOpen()) {
        this.recBlob = null;
        this.rec.start();

        if (this.showWave) {
          this.wave = Recorder.WaveView({elem: ".record-sound-wave"});
        }
        console.log("已开始录音...");
      } else {
        console.log("未打开录音", 1);
      }
    },
    stop() {
      if (!(this.rec && Recorder.IsOpen())) {
        console.log("未打开录音", 1);
        return;
      }
      this.rec.stop((blob, duration) => {
        console.log(blob, (window.URL || webkitURL).createObjectURL(blob), "时长:" + duration + "ms");

        this.recBlob = blob;
        console.log("已录制mp3：" + this.formatMs(duration) + "ms " + blob.size + "字节，可以点击播放、上传了", 2);
        // this.upload()
        this.$emit('onComplete', this.recBlob, duration)
        // this.iatUpload(duration, blob.size)
      }, (msg) => {
        console.log("录音失败:" + msg, 1);
      });
      this.wave = '';
    },
    formatMs(ms, all) {
      var f = Math.floor(ms / 60000), m = Math.floor(ms / 1000) % 60;
      var s = (all || f > 0 ? (f < 10 ? "0" : "") + f + ":" : "")
          + (all || f > 0 || m > 0 ? ("0" + m).substr(-2) + "″" : "")
          + ("00" + ms % 1000).substr(-3);
      return s;
    },
    upload() {
      const formData = new FormData()
      formData.append('blob', this.recBlob)
      Axios({
        method: 'POST',
        url: IAT_URL,
        baseURL: IAT_BASE_URL,
        data: this.recBlob,
        headers: {
          'X-NLS-Token': TOKEN,
          'Content-type': 'application/octet-stream',
        },
        params: {
          appkey: APP_KEY
        }
      })
    },
    iatUpload(duration, fileSize) {
      const md5 = MD5(IAT_APP_ID + new Date().getTime())
      const hmacSHA1Value = hmacSHA1(md5.toString(), IAT_SECRET_KEY)
      const signa = Base64.stringify(hmacSHA1Value)

      Axios({
        method: 'POST',
        url: IAT_URL,
        baseURL: IAT_BASE_URL,
        data: this.recBlob,
        headers: {
          'Content-type': 'application/octet-stream',
        },
        params: {
          signa: signa,
          fileSize,
          duration
        }
      })
    }
  },
  mounted() {
    this.initRecord()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
</style>
