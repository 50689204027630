export const serverAddress = 'https://ai.api.1app.site/'
export const hubUrl = 'https://ai.api.1app.site/VoiceMessageHub'
export const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VyTmFtZSI6ImFkbWluIiwiSUQiOiIyIiwiZXhwIjoxNTk5NjM3NjIxLCJpc3MiOiJuZXRsb2NrIiwiYXVkIjoibmV0bG9ja3MifQ.9T1zw2LaCx4enZLj5RCfxhJ85a169NPMqmW0n5OlzgI'
export const APPID= "5682087c"
export const Tencent_ID = "1318105519"
export const Tencent_SECERT_ID = "AKIDhKEyb7TdcR4ThmdJqzynP0UCkHlRxNek"
export const Tencent_SECERT_KEY = "4xhJn7LQUP4d4Sr6PWUHqLsV0Uq7FG6p"
export const API_KEY= "4f03a2fae35eca4b27d2030955795274"
export const XINZHI_KEY= "psva5njsss80d78d"
export const API_SECRET= "NjhkNDY5NDExZmI2MDgxNDRlMjFkOWVl"
