<template>
  <div class="party-container main-bg">
    <section v-show="!showRecord">
      <div class="back brown" @click="back">回首页</div>
      <div v-if="meetingList.length" class="meeting__summary-container">
        <div class="meeting__summary-title">党员大会</div>
        <meeting-summary v-for="item of meetingList" :key="item.id" :info="item" @onClick="goMeeting"></meeting-summary>
      </div>
      <div v-if="!meetingList.length" class="party__step-container">
        <el-steps direction="vertical" :active="step">
<!--          <el-step>-->
<!--            <div class="step__icon" slot="icon"></div>-->
<!--            <span class="step__title" slot="title">请选择党员大会类型：-->
<!--              <span v-if="meetingType">{{-->
<!--                transform(meetingType, allMeetingType)-->
<!--              }}-->
<!--              </span>-->
<!--            </span>-->
<!--            <div slot="description">-->
<!--              <div class="step__selection-container" v-if="step === 0">-->
<!--                <div class="step__selection" v-for="item of allMeetingType" @click="selectMeetingType(item.id)">-->
<!--                  <img class="step__img" v-if="meetingType === item.id" src="@/assets/select.png" alt="">-->
<!--                  {{ item.value }}-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="step__button" @click="next" v-if="meetingType && step === 0">下一步</div>-->
<!--            </div>-->
<!--          </el-step>-->
          <el-step>
            <div class="step__icon" slot="icon"></div>
            <span slot="title" class="step__title">请在确认准备工作完成后开启智慧会议记录</span>
            <div slot="description">
              <div class="step__button second" @click="next" v-if="step === 1">已准备完成，下一步</div>
            </div>
          </el-step>
<!--          <el-step>-->
<!--            <div class="step__icon" slot="icon"></div>-->
<!--            <span class="step__title" slot="title">会议议程确认</span>-->
<!--            <div slot="description">-->
<!--&lt;!&ndash;              <el-checkbox-group v-model="checkList" v-if="step === 2" text-color="#C40808" fill="#C40808">&ndash;&gt;-->
<!--&lt;!&ndash;                <el-checkbox label="1.统计到会人数并宣布会议是否有效"></el-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;                <el-checkbox label="2.提出议题展开讨论，进行表决形成决议"></el-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;                <el-checkbox label="3.进行表决形成决议"></el-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;                <el-checkbox label="4.整理会议记录"></el-checkbox>&ndash;&gt;-->
<!--&lt;!&ndash;              </el-checkbox-group>&ndash;&gt;-->
<!--              <div class="step__button third" @click="next" v-if="checkList.length === 4 && step === 2">下一步</div>-->
<!--            </div>-->
<!--          </el-step>-->
          <el-step>
            <div class="step__icon" slot="icon"></div>
            <span slot="title" class="step__title">开始会议</span>
            <div slot="description">
              <div class="step__button forth" @click="record" v-if="step === 2">开启智慧会议</div>
            </div>
          </el-step>
        </el-steps>
      </div>
    </section>
    <section class="container" v-show="showRecord">
      <div class="back brown" @click="back">回首页</div>
      <div class="record-container">
        <middleContent :startRecord="showRecord" :meeting-info="meetingInfo"></middleContent>
      </div>
    </section>
  </div>
</template>

<script>
import middleContent from "@/components/middleContent.vue";
import {createMeeting, getAllMeeting} from "@/service/meeting.service";
import moment from "moment/moment";
import MeetingSummary from "@/components/meetingSummary.vue";
import {MeetingType} from "@/utils/enum";

export default {
  name: "partyMember",
  data() {
    return {
      allMeetingType: [
        {
          id: MeetingType['ZHIBU'],
          value: '支部党员大会'
        },
        {
          id: MeetingType['MINZHU'],
          value: '民主评议大会'
        }
      ],
      showRecord: false,
      step: 1,
      meetingType: '1004',
      checkList: ["1.统计到会人数并宣布会议是否有效","2.提出议题展开讨论，进行表决形成决议","3.进行表决形成决议","4.整理会议记录"],
      meetingInfo: {},
      meetingList:[]
    }
  },
  components: {MeetingSummary, middleContent},
  mounted() {
    this.getMeetingInfo()
  },
  methods: {
    selectMeetingType(type) {
      this.meetingType = type
    },
    next() {
      this.step++
    },
    record() {
      createMeeting({
        "subject": '会议记录' + moment().format('YYYYMMDD'),
        "meetingTime": new Date(),
        "meetingType": this.meetingType,
        "location": "静安寺街道社区党群服务中心"
      })
          .then(res => {
            this.meetingInfo = {
              meetingId: res.id
            }
            this.showRecord = true;
          })
    },
    getMeetingInfo() {
      getAllMeeting({
        status: 'New',
        "meetingType": MeetingType['PARTYMEMBER'],
        day: moment().format('YYYYMMDD')
      })
          .then(res => {
            this.meetingList = [...res]
          })
    },
    goMeeting(id) {
      this.meetingInfo = {
        meetingId: id
      }
      this.showRecord = true;
    },
    transform(id, array) {
      let _result = ''
      for (const item of array) {
        if (item['id'] === id) {
          _result = item['value']
        }
      }

      return _result
    },
    back() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/style/px2rem";

.party-container {
  height: 100%;
  //background: linear-gradient(134deg, rgba(255, 250, 250, 0.5) 0%, rgba(255, 242, 242, 0.50) 50%), url('@/assets/partynav_bg.png');
  //padding: px2rem(70px);
}

.party-container.no-padding {
  padding: 0;
}

.party__step-container {
  padding: px2rem(92px) px2rem(250px);
}

.step {
  &__icon {
    width: px2rem(20px);
    height: px2rem(20px);
    border-radius: 50px;
    background: #D2D2D2;
  }

  &__title {
    font-size: px2rem(36px);
  }

  &__selection-container {
    padding: px2rem(32px) 0;
    display: flex;
  }

  &__img {
    width: px2rem(42px);
    height: px2rem(42px);
    vertical-align: bottom;
  }

  &__selection {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #C40808;
    font-size: px2rem(32px);
    cursor: pointer;
    width: px2rem(312px);
    background: white;
    text-align: center;
    padding: px2rem(20px) 0;
    margin-right: px2rem(68px);
  }

  &__button {
    display: inline-block;
    font-size: px2rem(36px);
    text-align: center;
    width: auto;
    height: px2rem(68px);
    padding: 0 px2rem(76px);
    line-height: px2rem(68px);
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 34px;
    border: 1px solid #FF5F28;
    color: #FF5F28;
  }

  &__button.second,
  &__button.third,
  &__button.forth {
    margin: px2rem(50px) 0;
  }
}

.meeting {
  &__summary-container {
    width: 80%;
    margin: auto;
    padding: px2rem(90px) 0;
    text-align: left;
  }

  &__summary-title {
    font-weight: 600;
    font-size: px2rem(42px);
    padding-bottom: px2rem(40px);
    color: #C40808;
  }
}

.container {
  height: 100%;
}

.record-container {
  width: px2rem(2000px);
  margin: auto;
  height: calc(100% - 37px);
  padding-top: px2rem(37px);
}

.step__icon {
  width: px2rem(32px);
  height: px2rem(32px);
}

.is-finish .step__icon {
  background: linear-gradient(219deg, #FF964F 0%, #FF5D27 100%);;
}

:deep(.el-checkbox-group) {
  display: flex;
  flex-direction: column;
}

:deep(.el-checkbox__label) {
  color: #C40808;
  margin-top: px2rem(23px);
  font-size: px2rem(32px);
}

:deep(.el-checkbox__input.is-checked+.el-checkbox__label) {
  color: #C40808;
}

:deep(.el-checkbox__input.is-checked .el-checkbox__inner) {
  background-color: #C40808;
  border-color: #C40808;
}

:deep(.el-step__icon.is-text) {
  border: none;
  background: transparent;
}

:deep(.el-step__main) {
  position: relative;
  margin-bottom: px2rem(38px);
  margin-left: px2rem(50px);
}

:deep(.el-step__line) {
  display: none;
}

:deep(.el-step__title.is-process), :deep(.el-step__title.is-finish) {
  font-size: px2rem(42px);
  color: #C40808;
  font-weight: bold;
}
</style>
