import {Tencent_ID, Tencent_SECERT_ID, Tencent_SECERT_KEY} from "@/utils/config";
import {v4 as uuidv4} from 'uuid';

const CryptoJS = require("crypto-js");

export function getWebSocketUrl() {
    const timestamp = parseInt(new Date().getTime() / 1000) - 1;
    const params = {
        secretid: Tencent_SECERT_ID,
        timestamp: timestamp,
        expired: timestamp + 60 * 60,
        nonce: timestamp,
        engine_model_type: '16k_zh_dialect',
        voice_id: timestamp.toString(),
        needvad: 1,
        voice_format: 12,
    };

    const url = 'asr.cloud.tencent.com/asr/v2/'


    let newurl = url + Tencent_ID + '?' + Object.keys(params)
        .sort(function (a, b) {
            return a.localeCompare(b);
        })
        .map((key) => {
            return encodeURIComponent(key) + '='
                + encodeURIComponent(params[key])
        })
        .join('&')

    let baseurl = baseUrl(newurl)
    newurl = newurl + '&' + 'signature' + '='
        + encodeURIComponent(baseurl)
    return 'wss://' + newurl
}

function baseUrl(newurl) {
    const hash = CryptoJS.HmacSHA1(newurl, Tencent_SECERT_KEY);
    return ArrayBufferToBase64(toUint8Array(hash));
}

function toUint8Array(wordArray) {
    // Shortcuts
    const words = wordArray.words;
    const sigBytes = wordArray.sigBytes;

    // Convert
    const u8 = new Uint8Array(sigBytes);
    for (let i = 0; i < sigBytes; i++) {
        u8[i] = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
    }
    return u8;
}


function ArrayBufferToBase64(buffer) {
    //第一步，将ArrayBuffer转为二进制字符串
    var binary = '';
    var bytes = new Uint8Array(buffer);
    for (var len = bytes.byteLength, i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    //将二进制字符串转为base64字符串
    return window.btoa(binary);
}
