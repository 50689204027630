<template>
  <div class="party-lesson main-bg">
    <div class="back brown" @click="back">回首页</div>
    <div class="tab-container">
      <el-tabs v-model="activeName" @tab-click="tabChange(activeName)">
        <el-tab-pane label="线下党课预约" name="third">
          <teacher-table></teacher-table>
        </el-tab-pane>
        <el-tab-pane label="党课案例" name="fourth">
          <div class="website__container" v-if="!isShowLesson">
            <div v-for="item of lessonList" class="website__item" :style="`backgroundImage: url(${item.background})`"
                 @click="showLesson(item.id)">
              <div class="website__name" v-html="item.name"></div>
            </div>
          </div>
          <div v-else>
            <div class="lesson__nav">
              <div class="lesson__nav-item" @click="backLessonNav">< 返回</div>
              <div class="lesson__nav-item right" @click="nextLesson">
                <span v-if="selectLessonContent.nextLabel">
                  {{ `下一篇：《${selectLessonContent.nextLabel}》` }}
                </span>
              </div>
            </div>

            <div class="lesson__content">
              <img :src="selectLessonContent.content" style="width: 100%" alt="">
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane class="tabs" label="相关网站" name="first">
          <div class="website__container">
            <div v-for="item of webList" class="website__item" :style="`backgroundImage: url(${item.background})`"
                 @click="navigate(item.url)">
              <div class="website__name">{{ item.name }}</div>
              <div class="website__url">{{ item.url }}</div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="党建iptv直播课堂" name="second">
          <div class="website__item IPTV"
               @click="navigate('https://dyyjvideo.shjcdj.cn/livepage/player.html?event=dj__70')">
            <div class="website__name">上海党员干部现代远程教育平台</div>
            <div class="website__url">https://dyyjvideo.shjcdj.cn/livepage/player.html?event=dj__70</div>
          </div>
          <!--          <iframe class="party__frame" src="https://dyyjvideo.shjcdj.cn/livepage/player.html?event=dj__53"></iframe>-->
        </el-tab-pane>
        <el-tab-pane label="其他咨询" name="fifth">

          <div class="custom-form">
            <div  class="custom-form-title">其他咨询</div>

            <span class="form__label">请输入您的姓名</span>
            <el-input v-model="data.name" placeholder=""></el-input>

            <span class="form__label">请留下您的手机号</span>
            <el-input v-model="data.phone" placeholder=""></el-input>


            <span class="form__label">所在党组织</span>
            <el-input v-model="data.party" placeholder=""></el-input>


            <span class="form__label">或直接联系</span>
            <div class="form__label form__label--large">张老师&nbsp;54361571</div>

            <div slot="footer" class="dialog-footer">
              <div class="dialog-footer__button dialog-footer__button--primary" @click="confirm">确认</div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import customDialog from "@/components/customDialog";
import TeacherTable from "@/components/teacherTable.vue";
import web1 from "@/assets/website/web1.png"
import web2 from "@/assets/website/web2.png"
import web3 from "@/assets/website/web3.png"
import web4 from "@/assets/website/web4.png"
import bg1 from "@/assets/lesson/bg1.png"
import bg2 from "@/assets/lesson/bg2.png"
import bg3 from "@/assets/lesson/bg3.png"
import bg4 from "@/assets/lesson/bg4.png"
import content1 from "@/assets/lesson/content1.png"
import content2 from "@/assets/lesson/content2.png"
import content3 from "@/assets/lesson/content3.png"
import content4 from "@/assets/lesson/content4.png"
import {createSchedule} from "@/service/common.service";

export default {
  name: "partyLesson",
  data() {
    return {
      activeName: 'third',
      showDialog: false,
      isShowLesson: false,
      selectLesson: '',
      selectLessonContent: '',
      data: {},
      webList: [
        {
          name: '上海智慧党建网',
          url: 'https://www.shzhdj.sh.cn',
          background: web1
        },
        {
          name: '上海党员干部现代远程教育互联网平台',
          url: 'https://dyyj.shjcdj.cn/portal/index.html',
          background: web2
        },
        {
          name: '共产党员网',
          url: 'https://www.12371.cn/',
          background: web3
        },
        {
          name: '上海市各街道社区、乡镇党群服务中心联系表',
          url: 'https://www.shjcdj.cn/djWeb/zhuanti/dangjianfuwu/detail.html',
          background: web4
        }
      ],
      lessonList: [
        {
          id: 1,
          name: '刘长胜故居现场情景教学与<br/>党史教育党课',
          background: bg1,
          content: content1
        },
        {
          id: 2,
          name: '蔡元培故居特色情景党课',
          background: bg2,
          content: content2
        },
        {
          id: 3,
          name: '弘扬楼宇党建首创精神',
          background: bg3,
          content: content3
        },
        {
          id: 4,
          name: '“同心展厅”沉浸式情景党课',
          background: bg4,
          content: content4
        }
      ]
    }
  },
  components: {
    TeacherTable,
    customDialog
  },
  watch: {
    selectLesson(id) {
      if (id) {

        for (const [index, item] of this.lessonList.entries()) {
          if (item.id === id) {
            this.selectLessonContent = {
              nextLabel: this.lessonList[index + 1]?.name || '',
              ...item
            }
          }
        }
      }
    }
  },
  methods: {
    tabChange(tab) {
      this.showDialog = tab === 'fifth';
    },
    navigate(url) {
      window.open(url);
    },
    showLesson(id) {
      this.isShowLesson = true
      this.selectLesson = id
    },
    nextLesson() {
      this.selectLesson++
    },
    backLessonNav() {
      this.isShowLesson = false
    },
    close() {
      // this.showDialog = false
    },
    back() {
      this.$router.back()
    },
    confirm() {
      createSchedule({
        applyType: '1002',
        userName: this.data.name,
        userPhone: this.data.phone,
        dangzuzhi: this.data.party,
        note: "string"
      })
          .then(res => {
            console.log(res, 111)
            // this.close();
          })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/style/px2rem";

.party-lesson {
  height: 100%;
  padding: px2rem(50px) px2rem(100px);
}

.tab-container {
  height: 100%;
  padding: px2rem(53px) 0;
}

.party {
  &__frame {
    width: 100%;
    height: 100%;
  }
}

.website {
  &__container {
    display: flex;
    justify-content: space-around;
    height: 100%;
    flex-wrap: wrap;
  }

  &__item {
    margin-top: 30px;
    width: px2rem(1045px);
    height: px2rem(594px);
    padding: px2rem(50px);
    color: white;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }

  &__name {
    font-size: px2rem(64px);
    font-weight: 600;
    z-index: 999;
  }

  &__url {
    margin-top: px2rem(15px);
    font-size: px2rem(32px);
    z-index: 999;
  }
}

.lesson {
  &__nav {
    height: 21px;
    display: flex;
    justify-content: space-between;
  }

  &__nav-item {
    flex: 1;
    text-align: left;
    cursor: pointer;
  }

  &__nav-item.right {
    text-align: right;
  }

  &__content {
    //height: calc(100% - 21px);
    //padding-top: px2rem(70px);
  }
}

.IPTV {
  position: relative;
  width: 100%;
  height: 76%;
  //background-color: rgba(71,0,0,0.55);
  background-image: url('@/assets/lesson/IPTV.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(71, 0, 0, 0.55);
    //z-index: 2;
  }
}
.custom-form {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  margin: auto;
  background: #FFF2F2;
  text-align: left;
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  box-shadow: 0 1px 3px rgba(0,0,0,.3);

  &-title {
    color: #C40808;
    padding-bottom: 50px;
    font-size: px2rem(32px);
    font-weight: 600;
    text-align: center;
  }
}

.form__label {
  color: #C40808;
  font-size: px2rem(24px);

  &--large {
    font-size: px2rem(28px);
  }
}

.dialog-footer {
  padding-top: 20px;
  display: flex;
  //justify-content: space-between;

  &__button {
    cursor: pointer;
    border-radius: 50px;
    background: white;
    border: 1px solid #FF5D27;
    color: #FF5D27;
    padding: px2rem(10px) px2rem(84px);

    &--primary {
      width: 100%;
      text-align: center;
      color: white;
      background: linear-gradient(243deg, #FF964F 0%, #FF5D27 100%);;
    }
  }
}

:deep(.el-input) {
  margin: px2rem(14px) 0;
}

:deep(.el-dialog) {
  //right: -10%;
}

:deep(.el-tabs),
:deep(.el-tabs__content),
:deep(.el-tab-pane) {
  height: 100%;
}

:deep(#pane-fourth) {
  overflow: auto;
}

:deep(#pane-first) {
  overflow: auto;
}

:deep(#pane-third) {
  overflow-y: auto;
}

:deep(.el-tabs__item) {
  font-size: px2rem(32px);
}

:deep(.el-tabs__item.is-active) {
  color: #C40808;
  font-weight: bold;
  font-size: px2rem(32px);
}

:deep(.el-tabs__active-bar) {
  background-color: #C40808;
}
</style>
