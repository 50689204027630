<template>
  <div class="activity-table">
    <el-table
        :data="activityList"
        stripe
        style="width: 100%">
      <el-table-column
          prop="id"
          label=""
          width="60">
      </el-table-column>
      <el-table-column
          prop="name"
          label="活动名称"
          width="120">
      </el-table-column>
      <el-table-column
          prop="startTime"
          label="活动时间">
      </el-table-column>
      <el-table-column
          prop="partyMassPosition"
          label="所属党群服务阵地">
      </el-table-column>
      <el-table-column
          prop="activityCategory"
          label="活动类型">
      </el-table-column>
      ,
      <el-table-column
          prop="contactPerson"
          label="活动联系人">
      </el-table-column>

      <el-table-column
          prop="contact"
          label="更多操作">
        <template slot-scope="scope">
          <div class="activity-table__button--container">
            <div class="activity-table__button" v-if="scope.row.activityPhotos" @click="showImg(scope.row.activityPhotos)">查看活动照片</div>
            <div style="width: 84px" v-else></div>
            <div class="activity-table__button" @click="showDetail(scope.row)">详情</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-image-viewer v-if="showImgPreview" :on-close="() => {showImgPreview = false}"
                     :url-list="srcList"></el-image-viewer>
    <common-dialog :show="showDialog" :show-footer="false" :show-close="true" @onClose="closeDialog"
                   :title="'《活动名称》'" :width="'70%'">
      <div class="activity-dialog__container">
        <div class="activity-dialog__left-content">
          <div class="item">
            <span class="label">所属党群阵地</span>
            <span class="value">{{ detailInfo?.partyMassPosition }}</span>
          </div>
          <div class="item">
            <span class="label">是否活动暑假班</span>
            <span class="value">{{ detailInfo?.isSummerSchool ? '是' : '否' }}</span>
          </div>
          <div class="item">
            <span class="label">活动开始时间</span>
            <span class="value">{{ detailInfo?.startTime }}</span>
          </div>
          <div class="item">
            <span class="label">活动联系人</span>
            <span class="value">{{ detailInfo?.contactPerson }}</span>
          </div>
          <div class="item">
            <span class="label">活动方式</span>
            <span class="value">{{ detailInfo?.activityOptions }}</span>
          </div>
          <div class="item">
            <span class="label">活动场馆</span>
            <span class="value">{{ detailInfo?.activityVenue }}</span>
          </div>
          <div class="item">
            <span class="label">活动分类</span>
            <span class="value">{{ detailInfo?.activityCategory }}</span>
          </div>
        </div>
        <div class="activity-dialog__right-content">
          <div class="item">
            <span class="label">品牌（特色）项目</span>
            <span class="value">{{ detailInfo?.specialItems }}</span>
          </div>
          <div class="item"></div>
          <div class="item">
            <span class="label">活动结束时间</span>
            <span class="value">{{ detailInfo?.endTime }}</span>
          </div>
          <div class="item">
            <span class="label">联系方式</span>
            <span class="value">{{ detailInfo?.contactInfo }}</span>
          </div>
          <div class="item">
            <span class="label">场馆类型</span>
            <span class="value">{{ detailInfo?.activityVenue }}</span>
          </div>
          <div class="item">
            <span class="label">活动地址</span>
            <span class="value">{{ detailInfo?.address }}</span>
          </div>
          <div class="item">
            <span class="label">资源来源</span>
            <span class="value">{{ detailInfo?.resourceSource }}</span>
          </div>
        </div>
      </div>
      <div>
        <div class="item">
          <span class="label">活动照片</span>
          <span class="value" v-for="image of handleImg(detailInfo?.activityPhotos)">
            <el-image
                style="margin-right: 10px;max-width: 300px;"
                :src="image">
            </el-image>
          </span>
        </div>
      </div>
    </common-dialog>
  </div>
</template>
<script>
import CustomDialog from "@/components/customDialog.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import CommonDialog from "@/components/commonDialog.vue";
import {getActivities} from "@/service/common.service";
import {serverAddress} from "@/utils/config";

export default {
  name: "activityTable",
  components: {CommonDialog, CustomDialog, ElImageViewer},
  data() {
    return {
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      activityDetail: {
        belonging: '静安寺街道社区党群服务中心'
      },
      activityList: [],
      detailInfo: {},
      showDialog: false,
      showImgPreview: false,
    }
  },
  methods: {
    showImg(img) {
      this.srcList = this.handleImg(img)
      this.showImgPreview = true
    },
    showDetail(info) {
      this.detailInfo = {...info};
      this.showDialog = true
    },
    closeDialog() {
      this.detailInfo = {};
      this.showDialog = false
    },
    handleImg(url) {
      let urlList = []
      if (!url) {
        return
      }
      if (url.indexOf(',') !== -1) {
        let _urlList = url.split(',')
        let newList = []
        for (let item of _urlList) {
          newList.push(serverAddress + item)
        }
        urlList = [...newList]
      } else {
        urlList = [serverAddress + url]
      }
      return urlList
    }

  },
  mounted() {
    getActivities()
        .then(res => {
          this.activityList = res
        })
  }
}
</script>


<style scoped lang="scss">
@import "src/style/px2rem";

.activity-table {
  &__button--container {
    display: flex;
    justify-content: space-around;
  }

  &__button {
    cursor: pointer;
    //font-size: px2rem(28px);
    font-weight: 600;
    color: #FF6767;
  }
}

.activity-dialog {
  &__container {
    padding-top: px2rem(50px);
    border-top: 1px solid #FFB4B4;
    display: flex;
  }

  &__left-content,
  &__right-content {
    width: 50%;
    height: 100%;
  }
}

.item {
  display: flex;
  margin-bottom: px2rem(50px);
}

.label {
  font-size: px2rem(28px);
  font-weight: 600;
  color: #C40808;
  width: px2rem(300px);
  text-align: right;
}

.value {
  padding-left: px2rem(48px);
  font-size: px2rem(28px);
  font-weight: 400;
  color: #333333;
}

:deep(.el-table th.el-table__cell) {
  color: #666666;
  background-color: transparent;
}

/*最外层透明*/
:deep(.el-table), :deep(.el-table__expanded-cell) {
  background-color: transparent;
}

/* 表格内背景颜色 */
:deep(.el-table th),
:deep(.el-table tr),
:deep(.el-table td) {
  background-color: transparent;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background: rgba(255, 103, 103, 0.1);
}
:deep(.el-image-viewer__img) {
  max-width: 1000px!important;
}

</style>

<style>
/*最外层透明*/
.el-table, .el-table__expanded-cell {
  background-color: transparent;
}

/* 表格内背景颜色 */
.el-table th,
.el-table tr,
.el-table td {
  background-color: transparent;
}
</style>
