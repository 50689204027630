import Vue from 'vue'
import App from './App.vue'
import router from "@/router";
import style from '@/style/style.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vuex from 'vuex'
import store from "@/store/store";
// import VConsole from 'vconsole';

Vue.use(Vuex)
Vue.config.productionTip = false
Vue.use(ElementUI);

// const vConsole = new VConsole();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
