<template>
  <div class="menu-bars">

    <p class="menu-title">静安寺街道智慧党建</p>
    <div class="menu-bars-item-group">
      <el-menu
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          :router="true"
          background-color="transparent"
          text-color="#ffffff"
          active-text-color="#ffffff">

<!--        <el-menu-item index="/chat">-->
<!--          <img class="menu-icon" :src="require(`@/assets/chat.png`)" alt="">-->
<!--          <span>党建问答助手</span>-->
<!--        </el-menu-item>-->

        <el-menu-item index="/chat?isTest=true">
          <img class="menu-icon" :src="require(`@/assets/chat.png`)" alt="">
          <span>党建问答助手</span>
        </el-menu-item>

        <el-submenu index="2">
          <template slot="title">
            <img class="menu-icon" :src="require(`@/assets/smart_meeting.png`)" alt="">
            <span>三会一课指导</span>
          </template>
          <el-menu-item index="/partyMember">党员大会</el-menu-item>
          <el-menu-item index="/partyBranch">党支部委员会</el-menu-item>
          <el-menu-item index="/partyGroup">党小组会</el-menu-item>
          <el-menu-item index="/partyLesson">党课</el-menu-item>
        </el-submenu>

        <el-submenu index="3">
          <template slot="title">
            <img class="menu-icon" :src="require(`@/assets/data.png`)" alt="">
            <span>数据与感知</span>
          </template>
          <el-menu-item index="/sense">态势感知</el-menu-item>
        </el-submenu>

        <el-submenu index="4">
          <template slot="title">
            <img class="menu-icon" :src="require(`@/assets/oa.png`)" alt="">
            <span>党组织OA系统</span>
          </template>
          <el-menu-item index="/OA">基层党组织开展活动情况</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'menuBar',
  props: {
    navBar: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "src/style/px2rem";

.menu-bars {
  width: 20%;
  height: 100%;
  background: url("@/assets/nav_bar_bg.png");
  text-align: left;

  .menu-title {
    font-weight: bold;
    padding-top: px2rem(103px);
    padding-bottom: px2rem(136px);
    font-size: px2rem(30px);
    text-align: center;
    color: white;
    margin: 0;
  }

  .menu-icon {
    margin-right: px2rem(30px);
    width: px2rem(40px);
    cursor: pointer;
  }

  .menu-bars-item {
    display: flex;
    align-items: center;
    padding: px2rem(8px) px2rem(30px);
    justify-content: center;
    position: relative;

    &:hover {
      background-color: hsla(0, 0%, 100%, 0.3);
    }

    .title {
      font-size: px2rem(30px);
      color: #ffffff;
      font-weight: 400;
    }
  }
}

:deep(.el-submenu__title .el-submenu__icon-arrow) {
  color: white;
}

:deep(.el-menu) {
  border: none;
}

@keyframes open-menu-bar {
  0% {
    width: 0;
  }

  100% {
    width: 16%;
  }
}
@keyframes close-menu-bar {
  0% {
    width: 16%;
  }

  100% {
    width: 0;
  }
}
</style>
